import * as React from 'react';
import { IonCardContent, IonCardHeader, IonCardSubtitle, IonCardTitle, IonCard } from '@ionic/react';
import { useHistory } from 'react-router';
import { observer } from 'mobx-react';
import { isNil } from 'lodash';

import { rootState } from 'models/RootState';
import './ResortCard.css';

interface ContainerProps {
    title: string;
    address: string | null;
    image: string | null;
    id: number;
}

const ResortCard: React.FC<ContainerProps> = (props) => {
    const history = useHistory();
    const resortId = props.id;

    const viewResortById = (resortId: number): void => {
        rootState.setResort(resortId);
        // This prevents getResortAreas and setResortArea from using previously
        // set data before setResort above completes
        setTimeout(() => {
            const resortAreas = rootState.currentResort?.getResortAreas();
            setTimeout(() => {
                if (!isNil(resortAreas)) {
                    rootState.setResortArea(resortAreas[0]);
                }
                setTimeout(() => {
                    history.push('/resort/' + resortId);
                }, 100);
            }, 100);
        }, 100);
    };

    return (
        <IonCard onClick={() => viewResortById(resortId)}>
            <IonCardContent>
                <img src={props.image ?? '/assets/mocks/card-resort.png'} alt="" />
            </IonCardContent>
            <IonCardHeader>
                <IonCardTitle className="font-medium">{props.title}</IonCardTitle>
                <IonCardSubtitle>{props.address}</IonCardSubtitle>
            </IonCardHeader>
        </IonCard>
    );
};

export default observer(ResortCard);
