import * as React from 'react';
import { IonButton, IonGrid, IonRow, IonCol, IonContent } from '@ionic/react';
import { Redirect } from 'react-router';

import { rootState } from 'models/RootState';
import Layout from 'components/Layout';
import './Welcome.css';
import { observer } from 'mobx-react';

const WelcomePage: React.FC = () => {
    if (rootState.user?.isLoggedIn()) {
        return <Redirect to="/search" />;
    }

    return (
        <Layout id="welcome-page">
            {rootState.brand.isCustom() && (
                <style>
                    {`
                    #welcome-page ion-content {
                        background: ` +
                        rootState.brand?.color_background +
                        `;
                    }
                    `}
                </style>
            )}
            <IonContent scrollY={false}>
                <IonGrid>
                    <IonRow className="vh50">
                        <IonCol className="ion-text-center">
                            <img className="w-64 mt-80" src={rootState.brand?.logo} alt="Logo" />
                        </IonCol>
                    </IonRow>

                    <IonRow>
                        <IonCol className="ion-text-center">
                            {rootState.brand?.isCustom() ? (
                                <h1 className="text-white">{rootState.brand.welcome}</h1>
                            ) : (
                                <img src={rootState.brand.welcome} alt="Welcome Message" />
                            )}
                        </IonCol>
                    </IonRow>

                    <IonRow>
                        <IonCol className="col-grow">
                            <div className="form-stacked" style={{ width: '100%', maxWidth: '480px', margin: 'auto' }}>
                                <IonButton
                                    className="main-button rounded-full"
                                    href="/signup"
                                    color="gold"
                                    routerDirection="forward"
                                >
                                    SIGN UP
                                </IonButton>

                                <IonButton
                                    className="main-button rounded-full"
                                    href="/login"
                                    color="green"
                                    routerDirection="forward"
                                >
                                    LOGIN
                                </IonButton>
                            </div>
                        </IonCol>
                    </IonRow>
                </IonGrid>
            </IonContent>
        </Layout>
    );
};

export default observer(WelcomePage);
