import * as React from 'react';

import Layout from 'components/Layout';
import Booking from 'components/booking/Booking';

const ReservationPage: React.FC = () => (
    <Layout id="manage-reservation-page">
        <Booking isReservation={true} />
    </Layout>
);

export default ReservationPage;
