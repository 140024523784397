import * as React from 'react';
import { useState } from 'react';
import {
    IonContent,
    IonToolbar,
    IonButtons,
    IonMenuButton,
    IonTitle,
    IonHeader,
    IonRow,
    IonCol,
    IonAlert,
    IonButton,
    useIonViewDidEnter,
} from '@ionic/react';
import { observer } from 'mobx-react';
import { isArray, isEmpty } from 'lodash';

import { rootState } from 'models/RootState';
import Resort from 'models/Resort';
import { solayAPI } from 'services/SolayAPI';
import Layout from 'components/Layout';
import InputSearch from 'components/forms/input-search/InputSearch';
import ResortCard from 'components/resort/ResortCard';
import Text from 'components/text/Text';
import './Search.css';
import { errorReporter } from 'services/ErrorReporter';

const SearchPage: React.FC = () => {
    // Clear out any reserved items
    rootState.setReservedItems([]);

    const [message, setMessage] = useState<string>();
    const [searchResults, setSearchResults] = useState<typeof Resort.Type[] | null>();

    useIonViewDidEnter(() => {
        if (rootState.shouldLoadResorts()) {
            solayAPI.loadResorts().then(handleGetResortsSuccess, handleGetResortsError);
        }
    });

    const runSearch = (query = ''): void => {
        if (!isEmpty(rootState.resorts)) {
            setSearchResults(rootState.searchByResortName(query));
        } else {
            setMessage('We are downloading resorts, please try your search again.');
        }
    };

    const handleGetResortsSuccess = (response: any) => {
        if (response.data?.code === solayAPI.successCode) {
            rootState.setResorts(response);
            if (rootState.brand.isCustom()) {
                // Show the only available resort if white label
                setSearchResults(rootState.searchByResortName(''));
            }
        } else {
            setMessage('Error loading resorts: ' + response.data.message);
            errorReporter.error('solayAPI.loadResorts non-success', response);
        }
    };

    const handleGetResortsError = (error: any) => {
        const msg = error.response?.data?.message ?? error.message ?? 'Unknown error.';
        setMessage('Error downloading resorts: ' + msg);
    };

    return (
        <Layout id="page-search">
            <IonHeader>
                <IonToolbar>
                    <IonButtons slot="start">
                        <IonMenuButton color="green" />
                    </IonButtons>
                    <IonTitle className="ion-text-uppercase">{rootState?.brand?.name || 'Solay'}</IonTitle>
                </IonToolbar>
            </IonHeader>
            <IonContent className="ion-no-margin ion-no-padding">
                <IonAlert
                    isOpen={!isEmpty(message)}
                    onDidDismiss={() => setMessage('')}
                    message={message}
                    buttons={['OK']}
                    mode="ios"
                />
                <IonRow>
                    <IonCol>
                        <div id="content-search">
                            <div id="search-intro">
                                {rootState.isResortView() ? (
                                    <IonButton href="/guestBookings" color="gold" className="main-button max-w-full ">
                                        GUEST BOOKINGS
                                    </IonButton>
                                ) : (
                                    <Text className="leading-normal">
                                        easily book your chairs or cabanas, search for your resort
                                    </Text>
                                )}
                            </div>
                            <div id="search-input">
                                <InputSearch onSearch={runSearch} />
                            </div>
                        </div>
                    </IonCol>
                </IonRow>
                <IonRow>
                    <IonCol className="search-container">
                        {isArray(searchResults) && searchResults?.length !== 0 && (
                            <IonRow id="search-results" className="ion-align-items-start">
                                {searchResults.map((resort, idx) => {
                                    return (
                                        <ResortCard
                                            key={idx}
                                            id={resort.id}
                                            title={resort.name}
                                            address={resort.address}
                                            image={resort.resort_image}
                                        />
                                    );
                                })}
                            </IonRow>
                        )}
                        {isArray(searchResults) && searchResults?.length === 0 && (
                            <IonRow id="search-intro" className="ion-align-items-start no-found ">
                                <Text>
                                    your resort was not found, please try again soon as we are adding new resorts daily
                                </Text>
                            </IonRow>
                        )}
                    </IonCol>
                </IonRow>
            </IonContent>
        </Layout>
    );
};

export default observer(SearchPage);
