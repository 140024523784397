import { MaterialUiPickersDate } from '@material-ui/pickers/typings/date';
import { isEmpty, uniq, includes } from 'lodash';
import { types } from 'mobx-state-tree';
import moment from 'moment';

import ResortArea from './ResortArea';

const Resort = types
    .model('Resort', {
        id: types.identifierNumber,
        name: types.string,
        address: types.maybeNull(types.string),
        telephone: types.maybeNull(types.string),
        email: types.maybeNull(types.string),
        resort_image: types.maybeNull(types.string),
        pools: types.array(ResortArea),
        beaches: types.array(ResortArea),
        timezone: types.maybeNull(types.string),

        cabana_full_day_price: types.maybeNull(types.union(types.string, types.number)),
        cabana_full_day_price_weekend: types.maybeNull(types.union(types.string, types.number)),
        cabana_full_day_price_special: types.maybeNull(types.union(types.string, types.number)),
        cabana_half_day_price: types.maybeNull(types.union(types.string, types.number)),
        lounge_half_day_price: types.maybeNull(types.union(types.string, types.number)),
        lounge_full_day_price: types.maybeNull(types.union(types.string, types.number)),

        special_holiday_dates: types.array(types.string),
        blackout_dates: types.array(types.string),
        cabana_amenities: types.maybeNull(types.string),
        charge_lounges: types.maybeNull(types.integer),
        charge_cabanas: types.maybeNull(types.integer),
        till_date: types.maybeNull(types.string),
        activated_at: types.maybeNull(types.string),
    })
    .views((self) => ({
        getPrice(
            type: string,
            reservationTimeOption: string,
            reservationDate: MaterialUiPickersDate | Date | undefined | null,
            isSpecial = includes(self.special_holiday_dates, moment(reservationDate).format('YYYY-MM-DD')),
        ) {
            let price: any;
            // day 6 or 0 is Saturday or Sunday
            const isWeekend = [6, 0].includes(moment(reservationDate).day());

            if (type === 'cabana') {
                if (reservationTimeOption === 'all day') {
                    if (isSpecial) {
                        price = self.cabana_full_day_price_special ?? '0';
                    } else if (isWeekend) {
                        price = self.cabana_full_day_price_weekend ?? '0';
                    } else {
                        price = self.cabana_full_day_price ?? '0';
                    }
                } else {
                    price = self.cabana_half_day_price ?? '0';
                }
            }

            if (type === 'lounge') {
                if (reservationTimeOption === 'all day') {
                    price = self.lounge_full_day_price ?? '0';
                } else {
                    price = self.lounge_half_day_price ?? '0';
                }
            }

            return parseFloat(price);
        },
        hasResortArea() {
            return !isEmpty(this.getDefaultResortArea());
        },
        getDefaultResortArea() {
            let resortArea = null;
            if (!isEmpty(self.beaches)) {
                resortArea = self.beaches[0];
            } else if (!isEmpty(self.pools)) {
                resortArea = self.pools[0];
            }
            return resortArea;
        },
        getResortAreas() {
            return self.pools.concat(self.beaches);
        },
        /**
         * Lounge and cabana photos for the resort
         *
         * Array of URLs
         */
        getCabanaAndLoungePhotos() {
            const photos: Array<string> = [];

            // Pools
            self.pools.forEach((area) => {
                // Cabanas
                area.cabanas.forEach((cabana) => {
                    cabana.attachments.forEach((attachement) => {
                        if (attachement.path) {
                            photos.push(attachement.path);
                        }
                    });
                });
                // Lounges
                area.lounges.forEach((cabana) => {
                    cabana.attachments.forEach((attachement) => {
                        if (attachement.path) {
                            photos.push(attachement.path);
                        }
                    });
                });
            });

            // Beaches
            self.beaches.forEach((area) => {
                // Cabanas
                area.cabanas.forEach((cabana) => {
                    cabana.attachments.forEach((attachement) => {
                        if (attachement.path) {
                            photos.push(attachement.path);
                        }
                    });
                });
                // Lounges
                area.lounges.forEach((cabana) => {
                    cabana.attachments.forEach((attachement) => {
                        if (attachement.path) {
                            photos.push(attachement.path);
                        }
                    });
                });
            });

            return uniq(photos);
        },
    }));

export default Resort;
