import * as React from 'react';
import { useState } from 'react';
import { IonListHeader, IonCardContent, IonLabel, IonContent, useIonViewDidEnter, IonLoading } from '@ionic/react';
import { observer } from 'mobx-react';
import { chain } from 'lodash';
import moment from 'moment';
import { rootState } from 'models/RootState';
import { GuestBookingRecord } from 'models/GuestBookingRecord';
import { solayAPI } from 'services/SolayAPI';
import GuestBookingsListItem from 'components/guestBookings/GuestBookingsListItem';
import { errorReporter } from 'services/ErrorReporter';

interface GuestBookingProps {
    bookings?: Array<GuestBookingRecord>;
}

const GuestBookingsList: React.FC<GuestBookingProps> = () => {
    const [isLoading, setIsLoading] = useState(true);

    useIonViewDidEnter(() => {
        if (rootState.user?.resort?.id) {
            solayAPI.ordersByResortId(rootState.user?.resort?.id).then((response) => {
                rootState.setResortOrders(response.data.data);
            });
            setIsLoading(false);
        } else {
            alert('User not connected to a resort, please logout and try again.');
            errorReporter.error('GuestBookingsList user has no resort', rootState.user || 'No user on rootState');
            setIsLoading(false);
        }
    });

    return (
        <IonContent>
            <IonLoading isOpen={isLoading} message={'Loading guest bookings...'} />
            {rootState.resortOrders && rootState.resortOrders.length > 0 ? (
                <>
                    {chain(rootState.resortOrders)
                        .groupBy('reservation_date')
                        .map((records, reservation_date) => ({ reservation_date, records }))
                        .sortBy('reservation_date')
                        .reverse()
                        .value()
                        .map((booking) => {
                            return (
                                <IonCardContent key={booking.reservation_date}>
                                    <IonListHeader color="bookings-list-heading">
                                        <IonLabel color="bookings-list-heading-text">
                                            <h1>{moment(booking.reservation_date).format('dddd MMMM DD, YYYY')}</h1>
                                        </IonLabel>
                                    </IonListHeader>
                                    {booking.records.map((bookingRecord) => {
                                        return <GuestBookingsListItem order={bookingRecord} key={bookingRecord.id} />;
                                    })}
                                </IonCardContent>
                            );
                        })}
                </>
            ) : (
                <p>No bookings data</p>
            )}
        </IonContent>
    );
};

export default observer(GuestBookingsList);
