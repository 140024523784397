import { types } from 'mobx-state-tree';

import Attachement from './Attachement';

const Cabana = types.model('Cabana', {
    id: types.identifierNumber,
    beach_pool_id: types.maybeNull(types.integer),
    total_cabanas: types.maybeNull(types.integer),
    total_rows: types.maybeNull(types.integer),
    description: types.maybeNull(types.string),
    location: types.maybeNull(types.string),
    scaling_factor: types.maybeNull(types.string),
    placement: types.maybeNull(types.string),
    additional_amenities: types.maybeNull(types.string),
    attachments: types.array(Attachement),
    vicinity_map: types.maybeNull(types.string),
});

export default Cabana;
