import * as React from 'react';
import { useEffect, useState } from 'react';
import {
    IonCol,
    IonContent,
    IonGrid,
    IonLoading,
    IonRow,
    useIonViewDidLeave,
    useIonViewDidEnter,
    IonAvatar,
} from '@ionic/react';
import { Capacitor } from '@capacitor/core'; // Push notifications
import { Redirect } from 'react-router';
import { observer } from 'mobx-react';
import cx from 'classnames';
import moment from 'moment-timezone';
import { isEmpty } from 'lodash';

import { rootState } from 'models/RootState';
import { solayAPI } from 'services/SolayAPI';
import { NotificationService } from 'services/NotificationService';
import Layout from 'components/Layout';
import BackArrow from 'components/backArrow/BackArrow';
import MessageInput from 'components/messages/MessageInput';
import './Messages.css';

const Messages: React.FC = () => {
    const [isLoading, setIsLoading] = useState(false);
    const [messageCount, setMessageCount] = useState(0);

    const timezone = Intl.DateTimeFormat().resolvedOptions().timeZone ?? 'America/New_York';

    const scrollToBottom = () => {
        const offset = document.getElementById('lastMsg')?.offsetTop ?? 999999;
        const content = document.getElementById('messages-content') as HTMLIonContentElement;
        if (content) {
            content.scrollToPoint(0, offset);
        }
    };

    // Whenever messageCount changes scroll down
    useEffect(() => {
        scrollToBottom();
    }, [messageCount]);

    useIonViewDidEnter(() => {
        if (!rootState.user?.isSuperAdmin()) {
            getMessages();
        } else {
            // Admins already have messages loaded
            setIsLoading(false);
            // @TODO scroll to bottom
            scrollToBottom();
        }

        if (Capacitor.isPluginAvailable('PushNotifications')) {
            NotificationService.setupPushNotifications();
            console.log('Push is available');
        } else {
            console.warn('Push not available');
        }
    });

    const getMessages = () => {
        setIsLoading(true);
        solayAPI.getMessages(rootState.user?.id || -1).then(handleGetMessagesSuccess, handleGetMessagesError);
    };

    useIonViewDidLeave(() => {
        if (rootState.user?.isSuperAdmin()) {
            // We need to clear out the current user messages so we will be redirected to the list next time we return to /messages
            rootState.setMessages([]);
        }
    });

    // If we are an super admin and don't have messages, redirect to converstations list.
    if (isEmpty(rootState.userMessages) && rootState.user?.isSuperAdmin()) {
        return <Redirect to="/conversations" />;
    }

    const handleGetMessagesSuccess = (response: any) => {
        setIsLoading(false);
        if (response.data?.code === solayAPI.successCode) {
            rootState.setMessages(response.data.data);
            setMessageCount(response.data.data.length);
        } else {
            const msg = response?.data?.message;
            alert('Unable to get messages ' + msg);
        }
    };

    const handleGetMessagesError = (error: any) => {
        setIsLoading(false);
        const msg = error?.response?.data?.message ?? error.message ?? 'Error getting messages.';
        alert(msg);
    };

    return (
        <Layout>
            <BackArrow
                pageTitle="Messages"
                messagesReturn={rootState.user?.isResortAdmin() ? 'conversations' : 'search'}
            />
            <IonLoading isOpen={isLoading} message={'Loading Messages...'} />
            <IonContent id="messages-content" color="light" scrollEvents={true} className={cx(isLoading && 'hidden')}>
                {rootState.userMessages.map((msg) => {
                    return (
                        <IonGrid className="message-container" key={msg.id}>
                            <IonRow className={cx(msg.isSolay() ? 'justify-end' : 'justify-start')}>
                                <IonCol size="10" className="msgTimestamp justify-start">
                                    {moment.tz(msg.created_at, 'UTC').tz(timezone).fromNow()}
                                </IonCol>
                            </IonRow>
                            <IonRow>
                                {msg.isSolay() && (
                                    <IonCol className="items-start">
                                        <img src={rootState.brand.logo_square} className="avatar" alt="Avatar" />
                                    </IonCol>
                                )}

                                <IonCol
                                    className={cx(msg.isSolay() ? 'msg-type-1' : 'msg-type-2', 'justify-start')}
                                    size="10"
                                >
                                    <p>{msg.body || ''}</p>
                                </IonCol>

                                {!msg.isSolay() && (
                                    <IonCol className="items-start">
                                        <IonAvatar>
                                            <img
                                                src={rootState.user?.getAvatar()}
                                                className="avatar"
                                                alt="Personal Avatar"
                                            />
                                        </IonAvatar>
                                    </IonCol>
                                )}
                            </IonRow>
                        </IonGrid>
                    );
                })}
                <span id="lastMsg" />

                {isEmpty(rootState.userMessages) && <p className="ion-text-center">No messages</p>}
                <MessageInput getMessages={getMessages} />
            </IonContent>
        </Layout>
    );
};

export default observer(Messages);
