import * as React from 'react';
import { ReactElement, useState } from 'react';
import {
    IonContent,
    IonButton,
    IonIcon,
    IonGrid,
    IonRow,
    IonCol,
    IonInput,
    IonAlert,
    IonItem,
    IonAvatar,
    IonChip,
    IonLabel,
    IonLoading,
} from '@ionic/react';
import { CameraPhoto, CameraResultType, CameraSource, Plugins } from '@capacitor/core';
import { personOutline, mailOutline, lockClosedOutline, phonePortraitOutline } from 'ionicons/icons';
import { observer } from 'mobx-react';
import { useForm } from 'react-hook-form';
import { isEmpty } from 'lodash';

import { rootState } from 'models/RootState';
import { UpdateProfileRequest } from 'models/UpdateProfileRequest';
import { solayAPI } from 'services/SolayAPI';
import Layout from 'components/Layout';
import BackArrow from 'components/backArrow/BackArrow';
import PrimaryLabel from 'components/labels/primaryLabel/PrimaryLabel';
import './Profile.css';

const { Camera } = Plugins;

const Profile: React.FC = (): ReactElement => {
    const [mode, setMode] = useState('view');
    const { register, handleSubmit } = useForm();

    const [message, setMessage] = useState<string>('');
    const [newAvatar, setNewAvatar] = useState<string>('');

    const [isLoading, setIsLoading] = useState(false);

    const edit = () => {
        setMode('edit');
    };

    const onSubmit = (data: any) => {
        if (isEmpty(data.name)) {
            setMessage('The name must be a string');
        } else if (isEmpty(data.mobile_no)) {
            setMessage('You must provide a mobile phone');
        } else if (isEmpty(data.email)) {
            setMessage('The email must be a valid string');
        } else if (!isEmpty(data.password) && isEmpty(data.conPassword)) {
            setMessage('The password and confirmation must match the password');
        } else if (data.password !== data.conPassword) {
            setMessage('The password and confirmation must match the password');
        } else {
            const request: UpdateProfileRequest = {
                name: data.name,
                email: data.email,
                mobile_no: data.mobile_no,
            };

            if (newAvatar) {
                request.profile_img = newAvatar;
            }

            if (!isEmpty(data.password)) {
                request.password = data.password;
            }

            setIsLoading(true);
            solayAPI
                .userUpdate(request)
                .then((response) => {
                    if (response.data.code === solayAPI.successCode) {
                        solayAPI.userView().then((response) => {
                            setIsLoading(false);
                            rootState.login(response);
                            setMessage('You have successfully updated your profile.');
                            setMode('view');
                        });
                    } else {
                        setIsLoading(false);
                        const msg =
                            response.data.message ?? 'Unknown server error. Please try updating your profile again.';
                        setMessage('Error: ' + msg);
                        return;
                    }
                })
                .catch((error) => {
                    setIsLoading(false);
                    const msg =
                        error?.response?.data?.message ??
                        error.message ??
                        'Unknown server error. Please try updating your profile again.';
                    setMessage('Error: ' + msg);
                    return;
                });
        }
    };

    const changePicture = () => {
        Camera.getPhoto({
            quality: 90,
            allowEditing: true,
            resultType: CameraResultType.Base64,
            source: CameraSource.Photos,
        }).then((photo: CameraPhoto) => {
            if (photo.base64String) {
                // @TODO allow cropping: https://ionicframework.com/docs/native/crop
                setNewAvatar('data:image/png;base64,' + photo.base64String);
            }
        });
    };

    const getAvatar = () => {
        return newAvatar || rootState.user?.getAvatar();
    };

    return (
        <Layout id="page-profile">
            <IonContent scrollY={false} className="bg-wave">
                <BackArrow editView={edit} mode={mode} pageTitle="Profile" />
                <IonAlert
                    isOpen={!isEmpty(message)}
                    onDidDismiss={() => setMessage('')}
                    buttons={['OK']}
                    message={message}
                />
                <IonLoading
                    isOpen={isLoading}
                    onDidDismiss={() => setIsLoading(false)}
                    message={'Updating your profile...'}
                />

                {mode === 'view' && (
                    <IonGrid>
                        <IonRow>
                            <IonCol>
                                <IonAvatar className="mb-12 avatar">
                                    <img src={getAvatar()} alt="Profile" />
                                </IonAvatar>
                            </IonCol>
                        </IonRow>

                        <IonRow>
                            <IonCol>
                                <PrimaryLabel iconName={personOutline} iconSlot="start">
                                    {rootState.user?.name}
                                </PrimaryLabel>
                            </IonCol>
                        </IonRow>
                        <IonRow>
                            <IonCol>
                                <PrimaryLabel iconName={mailOutline} iconSlot="start">
                                    {rootState.user?.email}
                                </PrimaryLabel>
                            </IonCol>
                        </IonRow>
                        <IonRow>
                            <IonCol>
                                <PrimaryLabel iconName={phonePortraitOutline} iconSlot="start">
                                    {rootState.user?.mobile_no}
                                </PrimaryLabel>
                            </IonCol>
                        </IonRow>
                    </IonGrid>
                )}

                {mode === 'edit' && (
                    <IonGrid>
                        <form id="profile-form" onSubmit={handleSubmit(onSubmit)}>
                            <IonRow>
                                <IonCol>
                                    <IonChip onClick={() => changePicture()}>
                                        <IonAvatar className="avatar">
                                            <img src={getAvatar()} alt="Profile" />
                                        </IonAvatar>
                                        <IonLabel>Change Picture</IonLabel>
                                    </IonChip>
                                </IonCol>
                            </IonRow>
                            <IonRow>
                                <IonCol>
                                    <IonItem className="inputs">
                                        <IonInput
                                            type="text"
                                            autocapitalize="off"
                                            placeholder="Full Name"
                                            autofocus={true}
                                            inputmode="text"
                                            enterkeyhint="next"
                                            ref={register({ required: false })}
                                            name="name"
                                            value={rootState.user?.name}
                                        ></IonInput>
                                        <IonButton fill="clear" slot="start" disabled>
                                            <IonIcon icon={personOutline} />
                                        </IonButton>
                                    </IonItem>
                                </IonCol>
                            </IonRow>

                            <IonRow>
                                <IonCol>
                                    <IonItem className="inputs">
                                        <IonInput
                                            type="email"
                                            autocapitalize="off"
                                            placeholder="e-mail"
                                            autofocus={true}
                                            inputmode="email"
                                            enterkeyhint="next"
                                            ref={register({ required: false })}
                                            name="email"
                                            value={rootState.user?.email}
                                        ></IonInput>
                                        <IonButton fill="clear" slot="start" disabled>
                                            <IonIcon icon={mailOutline} />
                                        </IonButton>
                                    </IonItem>
                                </IonCol>
                            </IonRow>

                            <IonRow>
                                <IonCol>
                                    <IonItem className="inputs">
                                        <IonInput
                                            type="text"
                                            autocapitalize="off"
                                            placeholder="mobile phone"
                                            inputmode="tel"
                                            enterkeyhint="next"
                                            ref={register({ required: false })}
                                            name="mobile_no"
                                            value={rootState.user?.mobile_no}
                                        ></IonInput>
                                        <IonButton fill="clear" slot="start" disabled>
                                            <IonIcon icon={phonePortraitOutline} />
                                        </IonButton>
                                    </IonItem>
                                </IonCol>
                            </IonRow>

                            <IonRow>
                                <IonCol>
                                    <IonItem className="inputs">
                                        <IonInput
                                            type="password"
                                            autocapitalize="off"
                                            placeholder="New Password"
                                            autofocus={true}
                                            inputmode="text"
                                            enterkeyhint="next"
                                            ref={register({ required: false })}
                                            name="password"
                                        ></IonInput>
                                        <IonButton fill="clear" slot="start" disabled>
                                            <IonIcon icon={lockClosedOutline} />
                                        </IonButton>
                                    </IonItem>
                                </IonCol>
                            </IonRow>
                            <IonRow>
                                <IonCol>
                                    <IonItem className="inputs">
                                        <IonInput
                                            type="password"
                                            autocapitalize="off"
                                            placeholder="Confirm New Password"
                                            autofocus={true}
                                            inputmode="text"
                                            enterkeyhint="next"
                                            ref={register({ required: false })}
                                            name="conPassword"
                                        ></IonInput>
                                        <IonButton fill="clear" slot="start" disabled>
                                            <IonIcon icon={lockClosedOutline} />
                                        </IonButton>
                                    </IonItem>
                                    {/* This hidden submit needs to be here to get the form submition when you hit Enter  */}
                                    <input type="submit" className="hidden" />
                                </IonCol>
                            </IonRow>
                        </form>
                    </IonGrid>
                )}
            </IonContent>
        </Layout>
    );
};

export default observer(Profile);
