import * as React from 'react';
import { useState } from 'react';
import {
    IonGrid,
    IonRow,
    IonCol,
    IonContent,
    IonInput,
    IonIcon,
    IonButton,
    IonAlert,
    IonLoading,
    IonItem,
} from '@ionic/react';
import './Login.css';
import PrimaryButton from '../../components/buttons/PrimaryButton';
import { mailOutline, lockClosedOutline } from 'ionicons/icons';
import { useForm } from 'react-hook-form';
import { LoginRequest } from '../../models/LoginRequest';
import { isEmpty } from 'lodash';
import { Redirect } from 'react-router';
import { rootState } from 'models/RootState';
import { LoginResponse } from '../../models/LoginResponse';
import { solayAPI } from 'services/SolayAPI';
import { observer } from 'mobx-react';
import Layout from 'components/Layout';

interface LoginProps {
    foo: number;
}

const LoginPage: React.FC<LoginProps> = () => {
    const [isLoading, setIsLoading] = useState(false);
    const [alertMessage, setAlertMessage] = useState('');

    const { register, handleSubmit, errors } = useForm();
    const [loginType, setLoginType] = useState('guest');

    if (rootState.user?.isLoggedIn()) {
        return <Redirect to="/search" />;
    }

    const onResortLogin = () => {
        if (loginType === 'guest') {
            setLoginType('resort');
        }
        if (loginType === 'resort') {
            setLoginType('guest');
        }
    };

    const onSubmit = (data: LoginRequest) => {
        if (isEmpty(data) || errors.email) {
            setAlertMessage('Please enter your email and password.');
            return;
        }

        if (isEmpty(data.email)) {
            setAlertMessage('Please enter an email');
            return;
        }

        // https://www.regular-expressions.info/email.html
        const emailRegex = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/;
        if (!emailRegex.test(String(data.email).toUpperCase())) {
            setAlertMessage('Invalid e-mail address.');
            return;
        }

        if (isEmpty(data.password)) {
            setAlertMessage('Please enter a password.');
            return;
        }

        login(data);
    };

    const login = (loginRequest: LoginRequest) => {
        setIsLoading(true);

        solayAPI
            .userLogin(loginRequest)
            .then(function (response: LoginResponse) {
                if (response.data?.code === solayAPI.successCode) {
                    rootState.login(response, loginType);
                } else {
                    setAlertMessage(response.data.message ?? 'Unable to login.');
                    setIsLoading(false);
                }
            })
            .catch(function (error) {
                // @TODO better error handling
                setAlertMessage(error?.message ?? 'Unkown login error.');
                setIsLoading(false);
            });
    };

    return (
        <Layout id="page-login">
            {rootState.brand.isCustom() && (
                <style>
                    {`
                    #page-login ion-content {
                        background: ` +
                        rootState.brand?.color_background +
                        `;
                    }
                    `}
                </style>
            )}
            <IonContent>
                <IonLoading isOpen={isLoading} onDidDismiss={() => setIsLoading(false)} message={'Logging you in...'} />

                <IonAlert
                    isOpen={!isEmpty(alertMessage)}
                    onDidDismiss={() => setAlertMessage('')}
                    message={alertMessage}
                    buttons={['OK']}
                    mode="ios"
                />

                <form onSubmit={handleSubmit(onSubmit)}>
                    <IonGrid>
                        <IonRow className="vh50">
                            <IonCol className="ion-align-items-start ion-justify-content-center">
                                <img className="w-56 mt-80" src={rootState.brand?.logo} alt="Logo" />
                            </IonCol>
                        </IonRow>

                        <IonRow>
                            <IonCol className="col-grow">
                                <div
                                    className="form-stacked"
                                    style={{ width: '100%', maxWidth: '480px', margin: 'auto' }}
                                >
                                    <IonItem className="inputs rounded-full">
                                        <IonInput
                                            type="text"
                                            autocapitalize="off"
                                            placeholder="e-mail"
                                            autofocus={true}
                                            inputmode="email"
                                            enterkeyhint="next"
                                            ref={register({ required: false })}
                                            name="email"
                                        />
                                        <IonButton fill="clear" disabled>
                                            <IonIcon icon={mailOutline} />
                                        </IonButton>
                                    </IonItem>
                                    <IonItem className="inputs mt-6 rounded-full">
                                        <IonInput
                                            type="password"
                                            autocapitalize="off"
                                            autocomplete="off"
                                            placeholder="password"
                                            inputmode="text"
                                            name="password"
                                            enterkeyhint="go"
                                            ref={register({ required: false })}
                                        />
                                        <IonButton fill="clear" disabled>
                                            <IonIcon icon={lockClosedOutline}></IonIcon>
                                        </IonButton>
                                    </IonItem>
                                    {/* This hidden submit needs to be here to get the form submition when you hit Enter  */}
                                    <input
                                        name="loginType"
                                        type="hidden"
                                        value={loginType}
                                        ref={register({ required: false })}
                                    />
                                    <input type="submit" className="hidden" />
                                    <PrimaryButton className="mt-6 font-size-18" type="submit" color="green">
                                        LOGIN
                                    </PrimaryButton>
                                </div>
                            </IonCol>
                        </IonRow>

                        <IonRow>
                            <IonCol>
                                <IonButton className="footer-info" href="/forgot" fill="clear" size="small">
                                    forgot password?
                                </IonButton>
                            </IonCol>

                            <IonCol>
                                <IonButton className="footer-info" href="/signup" fill="clear" size="small">
                                    no account? <span className="ml-1 font-bold underline">sign up</span>
                                </IonButton>
                            </IonCol>
                        </IonRow>

                        <IonRow>
                            <IonCol>
                                {loginType === 'guest' && (
                                    <IonButton
                                        className="footer-info"
                                        fill="clear"
                                        size="small"
                                        onClick={onResortLogin}
                                    >
                                        Login as Resort Admin
                                    </IonButton>
                                )}

                                {loginType === 'resort' && (
                                    <IonButton
                                        className="footer-info"
                                        fill="clear"
                                        size="small"
                                        onClick={onResortLogin}
                                    >
                                        Login as Guest
                                    </IonButton>
                                )}
                            </IonCol>
                        </IonRow>
                    </IonGrid>
                </form>
            </IonContent>
        </Layout>
    );
};

export default observer(LoginPage);
