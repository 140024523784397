import * as React from 'react';
import { useState } from 'react';
import {
    IonGrid,
    IonRow,
    IonCol,
    IonContent,
    IonInput,
    IonIcon,
    IonButton,
    IonAlert,
    IonLoading,
    IonItem,
} from '@ionic/react';
import './Forgot.css';
import PrimaryButton from '../../components/buttons/PrimaryButton';
import { arrowForwardOutline, mailOutline } from 'ionicons/icons';
import { useForm } from 'react-hook-form';
import { isEmpty } from 'lodash';
import { solayAPI } from 'services/SolayAPI';
import { useHistory } from 'react-router';
import Layout from 'components/Layout';

interface ForgotProps {
    foo: number;
}

const ForgotPage: React.FC<ForgotProps> = () => {
    const [isLoading, setIsLoading] = useState(false);
    const [showAlert, setShowAlert] = useState('');
    const [alertMessage, setAlertMessage] = useState('');
    const { register, handleSubmit } = useForm();
    const history = useHistory();

    const onSubmit = (data: any) => {
        if (isEmpty(data) || isEmpty(data.email)) {
            setShowAlert('Please enter your e-mail');
            return;
        }

        // https://www.regular-expressions.info/email.html
        const emailRegex = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/;
        if (!emailRegex.test(String(data.email).toUpperCase())) {
            setAlertMessage('Invalid e-mail address.');
            return;
        }

        forgot(data);
    };

    const forgot = (forgotRequest: any) => {
        setIsLoading(true);

        solayAPI
            .userPasswordReset(forgotRequest.email)
            .then(function (response: any) {
                setIsLoading(false);
                if (response.data.message) {
                    setShowAlert(response.data.message);
                    history.push('/login');
                } else {
                    setShowAlert(response.message || 'Unable to reset password');
                }
            })
            .catch(function () {
                // @TODO better error handling
                setIsLoading(false);
                setShowAlert('Somthing went wrong, try again.');
            });
    };

    return (
        <Layout id="page-forgot">
            <IonAlert
                isOpen={!isEmpty(alertMessage)}
                onDidDismiss={() => setAlertMessage('')}
                message={alertMessage}
                buttons={['OK']}
                mode="ios"
            />
            <IonContent scrollY={false}>
                <IonLoading
                    isOpen={isLoading}
                    onDidDismiss={() => setIsLoading(false)}
                    message={'Submitting password reset'}
                />

                <IonAlert
                    isOpen={!isEmpty(showAlert)}
                    onDidDismiss={() => setShowAlert('')}
                    message={showAlert}
                    buttons={['OK']}
                />

                <form onSubmit={handleSubmit(onSubmit)}>
                    <IonGrid>
                        <IonRow className="vh50">
                            <IonCol className="ion-align-items-start ion-justify-content-center">
                                <img className="mt-80" src="/assets/logo/Solay-Launch-LogoWhite-1.svg" alt="" />
                            </IonCol>
                        </IonRow>

                        <IonRow>
                            <IonCol className="col-grow">
                                <div
                                    className="form-stacked"
                                    style={{ width: '100%', maxWidth: '480px', margin: 'auto' }}
                                >
                                    <IonItem className="inputs">
                                        <IonInput
                                            className=""
                                            type="text"
                                            autocapitalize="off"
                                            placeholder="e-mail"
                                            autofocus={true}
                                            inputmode="email"
                                            enterkeyhint="go"
                                            ref={register({ required: false })}
                                            name="email"
                                        ></IonInput>
                                        <IonButton className="icon-outer">
                                            <IonIcon icon={mailOutline} />
                                        </IonButton>
                                    </IonItem>
                                    {/* This hidden submit needs to be here to get the form submition when you hit Enter  */}
                                    <input type="submit" className="hidden" />
                                    <PrimaryButton type="submit">
                                        Reset Password
                                        <IonIcon icon={arrowForwardOutline}></IonIcon>
                                    </PrimaryButton>
                                    <div className="mt-32">
                                        <IonButton className="footer-info" href="/login" fill="clear" size="small">
                                            Have password?
                                        </IonButton>
                                        <IonButton className="footer-info" href="/login" fill="clear" size="small">
                                            Login here
                                        </IonButton>
                                    </div>
                                </div>
                            </IonCol>
                        </IonRow>
                    </IonGrid>
                </form>
            </IonContent>
        </Layout>
    );
};

export default ForgotPage;
