import * as React from 'react';
import { useState } from 'react';
import { useForm, Controller } from 'react-hook-form';
import {
    IonContent,
    IonItem,
    IonIcon,
    IonCol,
    IonRow,
    IonInput,
    IonLabel,
    IonButton,
    IonCheckbox,
    IonAlert,
    IonLoading,
} from '@ionic/react';
import { isEmpty } from 'lodash';
import PrimaryButton from '../../components/buttons/PrimaryButton';
import './Signup.css';
import { useHistory } from 'react-router';
import { personOutline, mailOutline, lockClosedOutline, phonePortraitOutline } from 'ionicons/icons';

import { solayAPI } from 'services/SolayAPI';
import { RegisterRequest } from 'models/RegisterRequest';
import { rootState } from 'models/RootState';
import Layout from 'components/Layout';

const SignupPage: React.FunctionComponent = () => {
    const history = useHistory();
    const [disabledBtn, setDisabledBtn] = useState(true);
    const [colorBtn, setColorBtn] = useState<string>('disabled');
    const { handleSubmit, control } = useForm();
    const [isLoading, setIsLoading] = useState(false);

    const [alertMessage, setAlertMessage] = useState('');

    const register = (data: RegisterRequest) => {
        // reset IonAlert
        setAlertMessage('');

        if (isEmpty(data.name)) {
            setAlertMessage('Enter Full Name');
            return;
        }

        if (isEmpty(data.email)) {
            setAlertMessage('Enter Email Address');
            return;
        }
        // https://www.regular-expressions.info/email.html
        const emailRegex = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/;
        if (!emailRegex.test(String(data.email).toUpperCase())) {
            setAlertMessage('Invalid e-mail address.');
            return;
        }

        if (isEmpty(data.mobile_no)) {
            setAlertMessage('Enter mobile number');
            return;
        }
        // Strip non-numerics
        data.mobile_no = data.mobile_no.replace(/\D/g, '');
        if (data.mobile_no.length < 10) {
            setAlertMessage('Invalid mobile number, must be 10 numbers.');
            return;
        }

        if (isEmpty(data.password)) {
            setAlertMessage('Enter Password.');
            return;
        }

        if (data.tos === false) {
            setAlertMessage('Please accept the terms and conditions.');
            return;
        }
        setIsLoading(true);
        solayAPI.userRegister(data).then(handleSuccess, handleError);
    };

    const handleSuccess = (response: any) => {
        setIsLoading(false);
        if (response.data.code === solayAPI.successCode) {
            rootState.login(response);
            history.push('/search');
        } else {
            setAlertMessage(response.data.message);
        }
    };

    const handleError = (response: any) => {
        setIsLoading(false);
        setAlertMessage(response.data.message ?? 'Unknown login error');
    };

    const tosChecked = () => {
        if (disabledBtn === true) {
            setColorBtn('gold');
            setDisabledBtn(false);
        } else {
            setColorBtn('disabled');
            setDisabledBtn(true);
        }
    };

    return (
        <Layout id="page-signup">
            {rootState.brand.isCustom() && (
                <style>
                    {`
                    #page-signup ion-content {
                        background: ` +
                        rootState.brand?.color_background +
                        `;
                    }
                    `}
                </style>
            )}
            <IonContent className="bg-water">
                <IonLoading isOpen={isLoading} onDidDismiss={() => setIsLoading(false)} message={'Signing you up...'} />
                <IonRow className="mb-80">
                    <IonCol className="ion-align-items-start ion-justify-content-center">
                        <img className="w-56 mt-80" src={rootState.brand?.logo} alt="Logo" />
                    </IonCol>
                </IonRow>

                <IonAlert
                    isOpen={!isEmpty(alertMessage)}
                    onDidDismiss={() => setAlertMessage('')}
                    message={alertMessage}
                    buttons={['OK']}
                    mode="ios"
                />

                <form
                    onSubmit={handleSubmit(register)}
                    className="form-stacked"
                    style={{ width: '100%', maxWidth: '480px', margin: 'auto' }}
                >
                    <IonRow>
                        <IonCol>
                            <Controller
                                render={({ onChange }) => (
                                    <IonItem className="inputs">
                                        <IonInput
                                            type="text"
                                            placeholder="full name"
                                            name="name"
                                            enterkeyhint="next"
                                            onIonChange={(e) => onChange(e.detail.value)}
                                        ></IonInput>
                                        <IonButton fill="clear" disabled>
                                            <IonIcon icon={personOutline} />
                                        </IonButton>
                                    </IonItem>
                                )}
                                defaultValue=""
                                control={control}
                                name="name"
                            />
                        </IonCol>
                    </IonRow>
                    <IonRow>
                        <IonCol>
                            <Controller
                                render={({ onChange }) => (
                                    <IonItem className="inputs">
                                        <IonInput
                                            type="text"
                                            placeholder="e-mail"
                                            name="email"
                                            enterkeyhint="next"
                                            onIonChange={(e) => onChange(e.detail.value)}
                                        ></IonInput>
                                        <IonButton fill="clear" disabled>
                                            <IonIcon icon={mailOutline} />
                                        </IonButton>
                                    </IonItem>
                                )}
                                defaultValue=""
                                control={control}
                                name="email"
                            />
                        </IonCol>
                    </IonRow>
                    <IonRow>
                        <IonCol>
                            <Controller
                                render={({ onChange }) => (
                                    <IonItem className="inputs">
                                        <IonInput
                                            type="tel"
                                            placeholder="mobile phone"
                                            name="mobile_no"
                                            enterkeyhint="next"
                                            onIonChange={(e) => onChange(e.detail.value)}
                                        ></IonInput>
                                        <IonButton fill="clear" disabled>
                                            <IonIcon icon={phonePortraitOutline} />
                                        </IonButton>
                                    </IonItem>
                                )}
                                defaultValue=""
                                control={control}
                                name="mobile_no"
                            />
                        </IonCol>
                    </IonRow>
                    <IonRow>
                        <IonCol>
                            <Controller
                                render={({ onChange }) => (
                                    <IonItem className="inputs">
                                        <IonInput
                                            type="password"
                                            placeholder="password"
                                            name="password"
                                            enterkeyhint="go"
                                            onIonChange={(e) => onChange(e.detail.value)}
                                        ></IonInput>
                                        <IonButton fill="clear" disabled>
                                            <IonIcon icon={lockClosedOutline} />
                                        </IonButton>
                                    </IonItem>
                                )}
                                defaultValue=""
                                control={control}
                                name="password"
                            />
                        </IonCol>
                    </IonRow>
                    <IonRow>
                        <IonCol size="6">
                            <IonButton
                                className="underline bold text-color-gold lowercase"
                                fill="clear"
                                href="/page/terms-and-conditions/TERMS%20&%20CONDITIONS/signup"
                            >
                                terms of use
                            </IonButton>
                        </IonCol>
                        <IonCol size="6">
                            <IonButton
                                className="underline bold text-color-gold lowercase"
                                fill="clear"
                                href="/page/privacy-policy/PRIVACY%20POLICY/signup"
                            >
                                privacy policy
                            </IonButton>
                        </IonCol>
                    </IonRow>
                    <IonRow>
                        <IonCol>
                            <IonItem className="ion-item-checkbox" onClick={tosChecked}>
                                <Controller
                                    render={({ onChange, value }) => (
                                        <IonCheckbox
                                            mode="md"
                                            checked={value}
                                            name="tos"
                                            onIonChange={(e) => onChange(e.detail.checked)}
                                        ></IonCheckbox>
                                    )}
                                    defaultValue={false}
                                    control={control}
                                    name="tos"
                                />
                                <IonLabel color="light" className="terms-checkbox text-color-white ion-text-wrap">
                                    I accept terms of use and privacy policy
                                </IonLabel>
                            </IonItem>
                        </IonCol>
                    </IonRow>

                    {/* This hidden submit needs to be here to get the form submition when you hit Enter  */}
                    <input type="submit" className="hidden" />
                    <PrimaryButton
                        color={colorBtn}
                        disabled={disabledBtn}
                        type="submit"
                        onSubmit={handleSubmit(register)}
                    >
                        SIGN UP
                    </PrimaryButton>
                    <IonRow>
                        <IonCol className="flex-no-wrap">
                            <p className="text-color-white font-size-16 lowercase whitespace-no-wrap">
                                Already have an account?
                            </p>
                            <IonButton
                                href="/login"
                                className="text-color-white bold font-size-16 lowercase underline"
                                fill="clear"
                                size="small"
                            >
                                Login here
                            </IonButton>
                        </IonCol>
                    </IonRow>
                </form>
            </IonContent>
        </Layout>
    );
};

export default SignupPage;
