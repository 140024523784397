import * as React from 'react';
import { IonContent } from '@ionic/react';
import { useParams } from 'react-router';

import Layout from 'components/Layout';
import ExploreContainer from 'components/ExploreContainer';
import BackArrow from 'components/backArrow/BackArrow';
import './Page.css';

const Page: React.FC = () => {
    const { name, pageTitle, content, signup } = useParams<{
        name: string;
        pageTitle: string;
        content: string;
        signup: string;
    }>();

    return (
        <Layout>
            <BackArrow pageTitle={pageTitle} isSignup={signup} />
            <IonContent className="bg-wave">
                <ExploreContainer name={name} content={content} />
            </IonContent>
        </Layout>
    );
};

export default Page;
