import * as React from 'react';

import TermsAndConditions from 'components/TermsAndConditions';
import './ExploreContainer.css';

interface ContainerProps {
    name: string;
    content: string;
}

const ExploreContainer: React.FC<ContainerProps> = ({ name, content }) => {
    return (
        <div>
            {name === 'about' ? (
                <div className="container">
                    <p>
                        Solay is an intuitive global technology application that will eliminate completely the early
                        wake up and frustration of the &quot;chair dash&quot; - promising to enhance every guest user’s
                        vacation experience. Guests who choose to reserve poolside and beachside seating with Solay pay
                        a nominal morning, afternoon, or all-day fee.
                        <br />
                        <br />
                        Every guest that uses Solay will make a positive environmental impact on conservation and
                        restoration of our oceans, as Solay has committed to giving back a portion of its revenue to
                        organizations supporting these efforts.
                        <br />
                        <br />
                        New features will be announced soon! Sleep in, your seat is set.
                    </p>
                </div>
            ) : name === 'notifications' ? (
                <div className="container">
                    <p>Enable or disable notifications in your Settings application.</p>
                </div>
            ) : name === 'terms-and-conditions' ? (
                <div className="container">
                    <TermsAndConditions />
                </div>
            ) : name === 'privacy-policy' ? (
                <div className="container">
                    <p>
                        Thank you for choosing to be part of our community at Resort Technologies, LLC, doing business
                        as Solay (&quot;Solay&quot;, &quot;we&quot;, &quot;us&quot;, or &quot;our&quot;). We are
                        committed to protecting your personal information and your right to privacy. If you have any
                        questions or concerns about our policy , or our practices with regards to your personal
                        information, please contact us at <a href="mailto: admin@solayapp.com"> admin@solayapp.com.</a>.
                    </p>
                    <br />
                    <p>
                        When you visit our website{' '}
                        <a href="https://www.solayapp.com " target="_blank" rel="noopener noreferrer">
                            https://www.solayapp.com
                        </a>
                        , mobile application, Facebook application, and use our services, you trust us with your
                        personal information. We take your privacy very seriously. In this privacy policy, we seek to
                        explain to you in the clearest way possible what information we collect, how we use it and what
                        rights you have in relation to it. We hope you take some time to read through it carefully, as
                        it is important. If there are any terms in this privacy policy that you do not agree with,
                        please discontinue use of our Sites or Apps and our services.
                    </p>
                    <br />
                    <p>
                        This privacy policy applies to all information collected through our website (such as <br />
                        <a href="https://www.solayapp.com " target="_blank" rel="noopener noreferrer">
                            https://www.solayapp.com
                        </a>
                        ), mobile application, Facebook application, (&quot;Apps&quot;), and/or any related services,
                        sales, marketing or events (we refer to them collectively in this privacy policy as the
                        &quot;Services&quot;).
                    </p>
                    <br />
                    <p>
                        Please read this privacy policy carefully as it will help you make informed decisions about
                        sharing your personal information with us.
                    </p>
                    <br />
                    <p>1. WHAT INFORMATION DO WE COLLECT?</p>
                    <br />
                    <p>Personal information you disclose to us</p>
                    <br />
                    <p>
                        In Short: We collect personal information that you provide to us such as name, address, contact
                        information, passwords and security data, payment information, and social media login data.
                    </p>
                    <br />
                    <p>
                        We collect personal information that you voluntarily provide to us when registering at the
                        Services or Apps, expressing an interest in obtaining information about us or our products and
                        services, when participating in activities on the Services or Apps or otherwise contacting us.
                    </p>
                    <br />
                    <p>
                        The personal information that we collect depends on the context of your interactions with us and
                        the Services or Apps, the choices you make and the products and features you use. The personal
                        information we collect can include the following:
                    </p>
                    <br />
                    <p>
                        Publicly Available Personal Information. We collect first name, maiden name, last name, and
                        nickname; email addresses; phone numbers; and other similar data.
                    </p>
                    <br />
                    <p>Personal Information Provided by You. We collect purchase history; and other similar data.</p>
                    <br />
                    <p>
                        Credentials. We collect passwords, password hints, and similar security information used for
                        authentication and account access.
                    </p>
                    <br />
                    <p>
                        Payment Data. We collect data necessary to process your payment if you make purchases, such as
                        your payment instrument number (such as a credit card number), and the security code associated
                        with your payment instrument. All payment data is stored by stripe. You may find their privacy
                        policy link(s) here:{' '}
                        <a href="https://stripe.com/privacy" target="_blank" rel="noopener noreferrer">
                            https://stripe.com/privac
                        </a>
                        .
                    </p>
                    <br />
                    <p>
                        Social Media Login Data. We provide you with the option to register using social media account
                        details, like your Facebook, Twitter or other social media account. If you choose to register in
                        this way, we will collect the Information described in the section called &quot;HOW DO WE HANDLE
                        YOUR SOCIAL LOGINS&quot; below.
                    </p>
                    <br />
                    <p>
                        All personal information that you provide to us must be true, complete and accurate, and you
                        must notify us of any changes to such personal information.
                    </p>
                    <br />
                    <p>Information automatically collected</p>
                    <br />
                    <p>
                        In Short: Some information – such as IP address and/or browser and device characteristics – is
                        collected automatically when you visit our Services or Apps.
                    </p>
                    <br />
                    <p>
                        We automatically collect certain information when you visit, use or navigate the Services or
                        Apps. This information does not reveal your specific identity (like your name or contact
                        information) but may include device and usage information, such as your IP address, browser and
                        device characteristics, operating system, language preferences, referring URLs, device name,
                        country, location, information about how and when you use our Services or Apps and other
                        technical information. This information is primarily needed to maintain the security and
                        operation of our Services or Apps, and for our internal analytics and reporting purposes.
                    </p>
                    <br />
                    <p>Like many businesses, we also collect information through cookies and similar technologies.</p>
                    <br />
                    <p>
                        Online Identifiers. We collect cookie identifiers, or others such as the ones used for analytics
                        and marketing; device&#39;s geolocation; and other similar data.
                    </p>
                    <br />
                    <p>Information collected through our Apps</p>
                    <br />
                    <p>
                        In Short: We may collect information regarding your geo-location, push notifications, and
                        Facebook permissions when you use our apps.
                    </p>
                    <br />
                    <p>If you use our Apps, we may also collect the following information:</p>
                    <ul>
                        <li>
                            Geo-Location Information. We may request access or permission to and track location-based
                            information from your mobile device, either continuously or while you are using our mobile
                            application, to provide location-based services. If you wish to change our access or
                            permissions, you may do so in your device’s settings.
                        </li>
                        <li>
                            Push Notifications. We may request to send you push notifications regarding your account or
                            the mobile application. If you wish to opt-out from receiving these types of communications,
                            you may turn them off in your device’s settings.
                        </li>
                        <li>
                            Facebook Permissions. We by default access your Facebook basic account information,
                            including your name, email, gender, birthday, current city, and profile picture URL, as well
                            as other information that you choose to make public. We may also request access to other
                            permissions related to your account, such as friends, check-ins, and likes, and you may
                            choose to grant or deny us access to each individual permission. For more information
                            regarding Facebook permissions, refer to the Facebook Permissions Reference page.
                        </li>
                    </ul>
                    <p>Information collected from other sources</p>
                    <br />

                    <p>
                        In Short: We may collect limited data from public databases, marketing partners, social media
                        platforms, and other outside sources.
                    </p>
                    <br />
                    <p>
                        We may obtain information about you from other sources, such as public databases, joint
                        marketing partners, social media platforms (such as Facebook), as well as from other third
                        parties. Examples of the information we receive from other sources include: social media profile
                        information (your name, gender, birthday, email, current city, state and country, user
                        identification numbers for your contacts, profile picture URL, and any other information that
                        you choose to make public); marketing leads and search results and links, including paid
                        listings (such as sponsored links). We will inform you about the source of information and the
                        type of information we have collected about you within a reasonable period after obtaining the
                        personal data, but at the latest within one month.
                    </p>
                    <br />
                    <p>2 . HOW DO WE USE YOUR INFORMATION?</p>
                    <br />
                    <p>
                        In Short: We process your information for purposes based on legitimate business interests, the
                        fulfillment of our contract with you, compliance with our legal obligations, and/or your
                        consent.
                    </p>
                    <br />
                    <p>
                        We use personal information collected via our Services or Apps for a variety of business
                        purposes described below. We process your personal information for these purposes in reliance on
                        our legitimate business interests, in order to enter into or perform a contract with you, with
                        your consent, and/or for compliance with our legal obligations. We indicate the specific
                        processing grounds we rely on next to each purpose listed below.
                    </p>

                    <p>We use the information we collect or receive:</p>

                    <ul>
                        <li>
                            To facilitate account creation and logon process. If you choose to link your account with us
                            to a third party account (such as your Google or Facebook account), we use the information
                            you allowed us to collect from those third parties to facilitate account creation and logon
                            process for the performance of the contract. See the section below headed &quot;HOW DO WE
                            HANDLE YOUR SOCIAL LOGINS&quot; for further information.
                        </li>

                        <li>
                            To send you marketing and promotional communications. We and/or our third party marketing
                            partners may use the personal information you send to us for our marketing purposes, if this
                            is in accordance with your marketing preferences. You can opt-out of our marketing emails at
                            any time (see the &quot;WHAT ARE YOUR PRIVACY RIGHTS&quot; below).
                        </li>

                        <li>
                            To send administrative information to you. We may use your personal information to send you
                            product, service and new feature information and/or information about changes to our terms,
                            conditions, and policies.
                        </li>

                        <li>
                            Fulfill and manage your orders. We may use your information to fulfill and manage your
                            orders, payments, returns, and exchanges made through the Services or Apps.
                        </li>

                        <li>
                            To post testimonials. We post testimonials on our Services or Apps that may contain personal
                            information. Prior to posting a testimonial, we will obtain your consent to use your name
                            and testimonial. If you wish to update, or delete your testimonial, please contact us
                        </li>
                    </ul>

                    <p>
                        at <a href="mailto: amanda@solayapp.com."> amanda@solayapp.com.</a> and be sure to include your
                        name, testimonial location, and contact information.
                    </p>

                    <ul>
                        <li>
                            Deliver targeted advertising to you. We may use your information to develop and display
                            content and advertising (and work with third parties who do so) tailored to your interests
                            and/or location and to measure its effectiveness.
                        </li>

                        <li>
                            Request Feedback. We may use your information to request feedback and to contact you about
                            your use of our Services or Apps.
                        </li>

                        <li>
                            To protect our Services. We may use your information as part of our efforts to keep our
                            Services or Apps safe and secure (for example, for fraud monitoring and prevention).
                        </li>

                        <li>
                            To enable user-to-user communications. We may use your information in order to enable
                            user-to-user communications with each user&#39;s consent.
                        </li>

                        <li>
                            To enforce our terms, conditions and policies for Business Purposes, Legal Reasons and
                            Contractual
                        </li>

                        <li>
                            To respond to legal requests and prevent harm. If we receive a subpoena or other legal
                            request, we may need to inspect the data we hold to determine how to respond.
                        </li>

                        <li>
                            To manage user accounts. We may use your information for the purposes of managing our
                            account and keeping it in working order.
                        </li>

                        <li>
                            To deliver services to the user. We may use your information to provide you with the
                            requested service.
                        </li>

                        <li>
                            To respond to user inquiries/offer support to users. We may use your information to respond
                            to your inquiries and solve any potential issues you might have with the use of our
                            Services.
                        </li>

                        <li>
                            For other Business Purposes. We may use your information for other Business Purposes, such
                            as data analysis, identifying usage trends, determining the effectiveness of our promotional
                            campaigns and to evaluate and improve our Services or Apps, products, marketing and your
                            experience. We may use and store this information in aggregated and anonymized form so that
                            it is not associated with individual end users and does not include personal information. We
                            will not use identifiable personal information without your consent.
                        </li>
                    </ul>
                    <p>3. WILL YOUR INFORMATION BE SHARED WITH ANYONE?</p>
                    <br />
                    <p>
                        In Short: We only share information with your consent, to comply with laws, to provide you with
                        services, to protect your rights, or to fulfill business obligations.
                    </p>
                    <br />
                    <p>We may process or share data based on the following legal basis:</p>

                    <ul>
                        <li>
                            Consent: We may process your data if you have given us specific consent to use your personal
                            information in a specific purpose.
                        </li>

                        <li>
                            Legitimate Interests: We may process your data when it is reasonably necessary to achieve
                            our legitimate business interests.
                        </li>
                        <li>
                            Performance of a Contract: Where we have entered into a contract with you, we may process
                            your personal information to fulfill the terms of our contract.
                        </li>

                        <li>
                            Legal Obligations: We may disclose your information where we are legally required to do so
                            in order to comply with applicable law, governmental requests, a judicial proceeding, court
                            order, or legal process, such as in response to a court order or a subpoena (including in
                            response to public authorities to meet national security or law enforcement requirements).
                        </li>
                        <li>
                            Vital Interests: We may disclose your information where we believe it is necessary to
                            investigate, prevent, or take action regarding potential violations of our policies,
                            suspected fraud, situations involving potential threats to the safety of any person and
                            illegal activities, or as evidence in litigation in which we are involved.
                        </li>
                    </ul>

                    <p>
                        More specifically, we may need to process your data or share your personal information in the
                        following situations:
                    </p>

                    <ul>
                        <li>
                            Vendors, Consultants and Other Third-Party Service Providers. We may share your data with
                            third party vendors, service providers, contractors or agents who perform services for us or
                            on our behalf and require access to such information to do that work. Examples include:
                            payment processing, data analysis, email delivery, hosting services, customer service and
                            marketing efforts. We may allow selected third parties to use tracking technology on the
                            Services or Apps, which will enable them to collect data about how you interact with the
                            Services or Apps over time. This information may be used to, among other things, analyze and
                            track data, determine the popularity of certain content and better understand online
                            activity. Unless described in this Policy, we do not share, sell, rent or trade any of your
                            information with third parties for their promotional purposes. We have contracts in place
                            with our data processors. This means that they cannot do anything with your personal
                            information unless we have instructed them to do it. They will not share your personal
                            information with any organisation apart from us. They will hold it securely and retain it
                            for the period we instruct.
                        </li>
                        <li>
                            Business Transfers. We may share or transfer your information in connection with, or during
                            negotiations of, any merger, sale of company assets, financing, or acquisition of all or a
                            portion of our business to another company.
                        </li>

                        <li>
                            Third-Party Advertisers. We may use third-party advertising companies to serve ads when you
                            visit the Services or Apps. These companies may use information about your visits to our
                        </li>
                    </ul>

                    <p>
                        Website(s) and other websites that are contained in web cookies and other tracking technologies
                        in order to provide advertisements about goods and services of interest to you.
                    </p>
                    <ul>
                        <li>
                            Affiliates. We may share your information with our affiliates, in which case we will require
                            those affiliates to honor this privacy policy. Affiliates include our parent company and any
                            subsidiaries, joint venture partners or other companies that we control or that are under
                            common control with us.
                        </li>

                        <li>
                            Business Partners. We may share your information with our business partners to offer you
                            certain products, services or promotions.
                        </li>

                        <li>
                            Offer Wall. Our Apps may display a third-party hosted &quot;offer wall.&quot; Such an offer
                            wall allows third-party advertisers to offer virtual currency, gifts, or other items to
                            users in return for acceptance and completion of an advertisement offer. Such an offer wall
                            may appear in our mobile application and be displayed to you based on certain data, such as
                            your geographic area or demographic information. When you click on an offer wall, you will
                            leave our mobile application. A unique identifier, such as your user ID, will be shared with
                            the offer wall provider in order to prevent fraud and properly credit your account.
                        </li>
                    </ul>
                    <p>4. WHO WILL YOUR INFORMATION BE SHARED WITH?</p>
                    <br />
                    <p>In Short: We only share information with the following third parties.</p>
                    <br />
                    <p>
                        We only share and disclose your information with the following third parties. We have
                        categorized each party so that you may be easily understand the purpose of our data collection
                        and processing practices. If we have processed your data based on your consent and you wish to
                        revoke your consent, please contact us.
                    </p>

                    <ul>
                        <li>
                            Allow Users to Connect to their Third-Party Accounts Stripe account, Facebook account,
                            Google account, Instagram account, PayPal account, Tumblr account and Youtube account
                        </li>

                        <li> Cloud Computing Services Amazon Web Services (AWS)</li>

                        <li> Communicate and Chat with Users MailChimp and Zendesk Chat</li>

                        <li> Content Optimization TripAdvisor widget</li>

                        <li>
                            Invoice and Billing Amazon Payments, Apple Pay, Android Pay, Google Wallet, PayPal, Stripe
                            and Square
                        </li>

                        <li>
                            Retargeting Platforms Google Analytics Remarketing, Facebook Custom Audience, Google Ads
                            Remarketing , Twitter Tailored Audiences and Twitter Remarketing
                        </li>

                        <li>
                            Social Media Sharing and Advertising Instagram advertising, Snapchat advertising, Tumblr
                            social plugins, Twitter advertising, Twitter social plugins, Facebook advertising and
                            Facebook social plugins
                        </li>

                        <li>
                            User Account Registration and Authentication Facebook Login, Google Sign-In, MailChimp
                            OAuth2, Stripe OAuth and Google OAuth 2.0
                        </li>

                        <li>
                            Web and Mobile Analytics Facebook Analytics, Google Analytics, Google Ads, TestFlight and
                            StatCounter
                        </li>
                        <li>Website Testing Google Website Optimizer and TestFlight</li>
                    </ul>
                    <p>5. DO WE USE COOKIES AND OTHER TRACKING TECHNOLOGIES?</p>
                    <br />
                    <p>
                        In Short: We may use cookies and other tracking technologies to collect and store your
                        information.
                    </p>
                    <br />
                    <p>
                        We may use cookies and similar tracking technologies (like web beacons and pixels) to access or
                        store information. Specific information about how we use such technologies and how you can
                        refuse certain cookies is set out in our Cookie Policy.
                    </p>

                    <p>6. DO WE USE GOOGLE MAPS?</p>
                    <br />
                    <p>In Short: Yes, we use Google Maps for the purpose of providing better service.</p>
                    <br />
                    <p>
                        This website, mobile application, or Facebook application uses Google Maps APIs. You may find
                        the Google Maps APIs Terms of Service here. To better understand Google’s Privacy Policy, please
                        refer to this link.
                    </p>

                    <p>
                        By using our Maps API Implementation, you agree to be bound by Google’s Terms of Service. By
                        using our implementation of the Google Maps APIs, you agree to allow us to gain access to
                        information about you including personally identifiable information (such as usernames) and
                        non-personally identifiable information (such as location). We will be collecting the following
                        information:
                    </p>
                    <ul>
                        <li>geolocation</li>
                    </ul>
                    <br />
                    <p>
                        For a full list of what we use information for, please see the previous section titled &quot;HOW
                        DO WE USE YOUR INFORMATION?&quot; and &quot;WILL YOUR INFORMATION BE SHARED WITH ANYONE?&quot;
                        You agree to allow us to obtain or cache your location. You may revoke your consent at anytime.
                    </p>
                    <br />
                    <p>
                        The Maps APIs that we use store and access cookies and other information on your devices. If you
                        are a user currently in the European Economic Area (EU countries, Iceland, Liechtenstein and
                        Norway), please take a look at our EU User Consent Policy.
                    </p>
                    <br />
                    <p>7. HOW DO WE HANDLE YOUR SOCIAL LOGINS?</p>
                    <br />
                    <p>
                        In Short: If you choose to register or log in to our services using a social media account, we
                        may have access to certain information about you.
                    </p>
                    <br />
                    <p>
                        Our Services or Apps offer you the ability to register and login using your third party social
                        media account details (like your Facebook or Twitter logins). Where you choose to do this, we
                        will receive certain profile information about you from your social media provider. The profile
                        Information we receive may vary depending on the social media provider concerned, but will often
                        include your name, e-mail address, friends list, profile picture as well as other information
                        you choose to make public. If you login using Facebook, we may also request access to other
                        permissions related to your account, such as friends, check-ins, and likes, and you may choose
                        to grant or deny us access to each individual permission.
                    </p>
                    <br />
                    <p>
                        We will use the information we receive only for the purposes that are described in this privacy
                        policy or that are otherwise made clear to you on the Services or Apps. Please note that we do
                        not control, and are not responsible for, other uses of your personal information by your third
                        party social media provider. We recommend that you review their privacy policy to understand how
                        they collect, use and share your personal information, and how you can set your privacy
                        preferences on their sites and apps.
                    </p>
                    <br />
                    <p>8. HOW LONG DO WE KEEP YOUR INFORMATION?</p>
                    <br />
                    <p>
                        In Short: We keep your information for as long as necessary to fulfill the purposes outlined in
                        this privacy policy unless otherwise required by law.
                    </p>
                    <br />
                    <p>
                        We will only keep your personal information for as long as it is necessary for the purposes set
                        out in this privacy policy, unless a longer retention period is required or permitted by law
                        (such as tax, accounting or other legal requirements). No purpose in this policy will require us
                        keeping your personal information for longer than 6 months past the termination of the
                        user&#39;s account.
                    </p>
                    <br />
                    <p>
                        When we have no ongoing legitimate business need to process your personal information, we will
                        either delete or anonymize it, or, if this is not possible (for example, because your personal
                        information has been stored in backup archives), then we will securely store your personal
                        information and isolate it from any further processing until deletion is possible.
                    </p>
                    <br />
                    <p>9. HOW DO WE KEEP YOUR INFORMATION SAFE?</p>
                    <br />
                    <p>
                        In Short: We aim to protect your personal information through a system of organizational and
                        technical security measures.
                    </p>
                    <br />
                    <p>
                        We have implemented appropriate technical and organizational security measures designed to
                        protect the security of any personal information we process. However, please also remember that
                        we cannot guarantee that the internet itself is 100% secure. Although we will do our best to
                        protect your personal information, transmission of personal information to and from our Services
                        or Apps is at your own risk. You should only access the services within a secure environment.
                    </p>
                    <br />
                    <p>10. DO WE COLLECT INFORMATION FROM MINORS?</p>
                    <br />
                    <p>In Short: We do not knowingly collect data from or market to children under 18 years of age.</p>
                    <br />
                    <p>
                        We do not knowingly solicit data from or market to children under 18 years of age. By using the
                        Services or Apps, you represent that you are at least 18 or that you are the parent or guardian
                        of such a minor and consent to such minor dependent’s use of the Services or Apps. If we learn
                        that personal information from users less than 18 years of age has been collected, we will
                        deactivate the account and take reasonable measures to promptly delete such data from our
                        records. If you become aware of any data we have collected from children under age 18, please
                        contact us at <a href="mailto: amanda@solayapp.com."> amanda@solayapp.com.</a>.
                    </p>
                    <br />
                    <p>11. WHAT ARE YOUR PRIVACY RIGHTS?</p>
                    <p>
                        In Short: In some regions, such as the European Economic Area, you have rights that allow you
                        greater access to and control over your personal information. You may review, change, or
                        terminate your account at any time.
                    </p>
                    <br />
                    <p>
                        In some regions (like the European Economic Area), you have certain rights under applicable data
                        protection laws. These may include the right (i) to request access and obtain a copy of your
                        personal information, (ii) to request rectification or erasure; (iii) to restrict the processing
                        of your personal information; and (iv) if applicable, to data portability. In certain
                        circumstances, you may also have the right to object to the processing of your personal
                        information. To make such a request, please use the contact details provided below. We will
                        consider and act upon any request in accordance with applicable data protection laws.
                    </p>
                    <br />
                    <p>
                        If we are relying on your consent to process your personal information, you have the right to
                        withdraw your consent at any time. Please note however that this will not affect the lawfulness
                        of the processing before its withdrawal.
                    </p>
                    <br />
                    <p>
                        If you are resident in the European Economic Area and you believe we are unlawfully processing
                        your personal information, you also have the right to complain to your local data protection
                        supervisory authority. You can find their contact details here:
                        <a
                            href="http://ec.europa.eu/justice/data-protection/bodies/authorities/index_en.htm."
                            target="_blank"
                            rel="noopener noreferrer"
                        >
                            http://ec.europa.eu/justice/data-protection/bodies/authorities/index_en.htm.
                        </a>
                    </p>
                    <br />
                    <p>
                        If you have questions or comments about your privacy rights, you may email us at
                        <a href="mailto: admin@solay.com."> admin@solay.com.</a>
                    </p>
                    <br />
                    <p>Account Information</p>
                    <br />
                    <p>
                        If you would at any time like to review or change the information in your account or terminate
                        your account, you can:
                    </p>

                    <ul>
                        <li>Log into your account settings and update your user account.</li>
                    </ul>
                    <br />
                    <p>
                        Upon your request to terminate your account, we will deactivate or delete your account and
                        information from our active databases. However, some information may be retained in our files to
                        prevent fraud, troubleshoot problems, assist with any investigations, enforce our Terms of Use
                        and/or comply with legal requirements.
                    </p>
                    <br />
                    <p>
                        Opting out of email marketing: You can unsubscribe from our marketing email list at any time by
                        clicking on the unsubscribe link in the emails that we send or by contacting us using the
                        details provided below. You will then be removed from the marketing email list – however, we
                        will still need to send you service-related emails that are necessary for the administration and
                        use of your account. To otherwise opt-out, you may:
                    </p>

                    <ul>
                        <li>Access your account settings and update preferences.</li>
                        <li>Note your preferences when you register an account with the site.</li>
                    </ul>
                    <p>12.DATA BREACH</p>
                    <br />
                    <p>
                        A privacy breach occurs when there is unauthorized access to or collection, use, disclosure or
                        disposal of personal information. You will be notified about data breaches when Resort
                        Technologies, LLC believes you are likely to be at risk or serious harm. For example, a data
                        breach may be likely to result in serious financial harm or harm to your mental or physical
                        well-being. In the event that Resort Technologies, LLC becomes aware of a security breach which
                        has resulted or may result in unauthorized access, use or disclosure of personal information
                        Resort Technologies, LLC will promptly investigate the matter and notify the applicable
                        Supervisory Authority not later than 72 hours after having become aware of it, unless the
                        personal data breach is unlikely to result in a risk to the rights and freedoms of natural
                        persons.
                    </p>
                    <br />
                    <p>13. CONTROLS FOR DO-NOT-TRACK FEATURES</p>
                    <br />
                    <p>
                        Most web browsers and some mobile operating systems and mobile applications include a
                        Do-Not-Track (&quot;DNT&quot;) feature or setting you can activate to signal your privacy
                        preference not to have data about your online browsing activities monitored and collected. No
                        uniform technology standard for recognizing and implementing DNT signals has been finalized. As
                        such, we do not currently respond to DNT browser signals or any other mechanism that
                        automatically communicates your choice not to be tracked online. If a standard for online
                        tracking is adopted that we must follow in the future, we will inform you about that practice in
                        a revised version of this privacy policy.
                    </p>
                    <br />
                    <p>14. DO CALIFORNIA RESIDENTS HAVE SPECIFIC PRIVACY RIGHTS?</p>
                    <br />
                    <p>
                        In Short: Yes, if you are a resident of California, you are granted specific rights regarding
                        access to your personal information.
                    </p>
                    <br />
                    <p>
                        California Civil Code Section 1798.83, also known as the &quot;Shine The Light&quot; law,
                        permits our users who are California residents to request and obtain from us, once a year and
                        free of charge, information about categories of personal information (if any) we disclosed to
                        third parties for direct marketing purposes and the names and addresses of all third parties
                        with which we shared personal information in the immediately preceding calendar year. If you are
                        a California resident and would like to make such a request, please submit your request in
                        writing to us using the contact information provided below.
                    </p>
                    <br />
                    <p>
                        If you are under 18 years of age, reside in California, and have a registered account with the
                        Services or Apps, you have the right to request removal of unwanted data that you publicly post
                        on the Services or Apps. To request removal of such data, please contact us using the contact
                        information provided below, and include the email address associated with your account and a
                        statement that you reside in California. We will make sure the data is not publicly displayed on
                        the Services or Apps, but please be aware that the data may not be completely or comprehensively
                        removed from our systems.
                    </p>
                    <br />
                    <p>15. DO WE MAKE UPDATES TO THIS POLICY?</p>
                    <br />
                    <p>In Short: Yes, we will update this policy as necessary to stay compliant with relevant laws.</p>
                    <br />
                    <p>
                        We may update this privacy policy from time to time. The updated version will be indicated by an
                        updated &quot;Revised&quot; date and the updated version will be effective as soon as it is
                        accessible. If we make material changes to this privacy policy, we may notify you either by
                        prominently posting a notice of such changes or by directly sending you a notification. We
                        encourage you to review this privacy policy frequently to be informed of how we are protecting
                        your information.
                    </p>
                    <br />
                    <p>16. HOW CAN YOU CONTACT US ABOUT THIS POLICY?</p>
                    <br />
                    <p>
                        If you have questions or comments about this policy, you may contact our Data Protection Officer
                        (DPO), Ms. Abbott, by email at <a href="mailto:help@solayapp.com">help@solayapp.com</a>, by
                        phone at 8583227341, or by post to:
                    </p>
                    <br />

                    <p>ResortTechologies</p>
                    <p>9984 Scripps Ranch Blvd. #214</p>
                    <p>San Diego, CA 92131</p>
                    <p> United States</p>

                    <br />
                    <p>17. HOW CAN YOU REVIEW, UPDATE, OR DELETE THE DATA WE COLLECT FROM YOU?</p>
                    <br />
                    <p>
                        Based on the laws of some countries, you may have the right to request access to the personal
                        information we collect from you, change that information, or delete it in some circumstances. To
                        request to review, update, or delete your personal information, please submit a request form by
                        clicking here. We will respond to your request within 30 days.
                    </p>
                </div>
            ) : name === 'terms-of-use' ? (
                <div className="container">
                    <p>
                        <a
                            href="https://app.termly.io/document/terms-of-use-for-website/2aad3777-1ce0-416e-842e-ff62d8ea4d91"
                            target="_blank"
                            className="underline"
                        >
                            Please click here to view our latest Terms of Use.
                        </a>
                    </p>
                </div>
            ) : (
                <div className="container" dangerouslySetInnerHTML={{ __html: content }}></div>
            )}
        </div>
    );
};

export default ExploreContainer;
