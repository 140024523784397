import { types } from 'mobx-state-tree';
import Message from './Message';

const Conversation = types
    .model('Conversation', {
        user_id: types.identifierNumber,
        name: types.maybe(types.string),
        profile_img: types.maybeNull(types.string),
        messages: types.array(Message),
    })
    .views((self) => ({
        getProfileImg() {
            return self.profile_img ?? '/assets/icon/avatar.jpg';
        },
        getMostRecentMessage() {
            return self.messages.slice(-1)[0];
        },
        getMostRecentMessageText() {
            return this.getMostRecentMessage().body;
        },
        getMostRecentMessageDate() {
            return this.getMostRecentMessage().created_at;
        },
        getMostRecentMessageUpdated() {
            return this.getMostRecentMessage().updated_at;
        },
    }));

export default Conversation;
