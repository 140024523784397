import { types } from 'mobx-state-tree';
import { rootState } from './RootState';

const Message = types
    .model('Message', {
        id: types.identifierNumber,
        body: types.maybe(types.string),
        sender_id: types.integer,
        receiver_id: types.integer,
        ref_id: types.integer,
        created_at: types.string,
        updated_at: types.maybeNull(types.string),
        deleted_at: types.maybeNull(types.string),
    })
    .views((self) => ({
        getUserId(): number {
            return this.isSolay() ? self.receiver_id : self.sender_id;
        },
        isSolay(): boolean {
            return self.sender_id === 0;
        },
        getSenderName(): string {
            return self.sender_id !== rootState.user?.id ? 'Solay App' : rootState.user?.name || '';
        },
    }));

export default Message;
