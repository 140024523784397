import { types } from 'mobx-state-tree';

const ResortAreaItem = types.model('ResortAreaItem', {
    id: types.maybeNull(types.number),
    x: types.maybeNull(types.number),
    y: types.maybeNull(types.number),
    type: types.maybeNull(types.string),
});

export default ResortAreaItem;
