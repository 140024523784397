import * as React from 'react';
import { PropsWithChildren } from 'react';
import { IonPage, IonToast } from '@ionic/react';
import { useHistory } from 'react-router';
import { observer } from 'mobx-react';

import { rootState } from 'models/RootState';

interface Props {
    id?: string;
    className?: string;
}

const Layout: React.FC<PropsWithChildren<Props>> = ({ children, ...props }) => {
    const history = useHistory();

    const onClickHandler = () => {
        if (rootState.toastMessage.data.sender_id) {
            history.push('/messages');
        } else {
            history.push('/reservations');
        }
    };

    return (
        <IonPage {...props}>
            {children}
            <IonToast
                isOpen={rootState.isToastOpen}
                buttons={[
                    {
                        text: 'View',
                        handler: onClickHandler,
                    },
                ]}
                onDidDismiss={() => rootState.setIsToastOpen(false)}
                message={rootState.toastMessage.body}
                duration={5000}
            />
        </IonPage>
    );
};

export default observer(Layout);
