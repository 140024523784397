import * as React from 'react';
import { IonContent } from '@ionic/react';

import Layout from 'components/Layout';
import BackArrow from 'components/backArrow/BackArrow';
import GuestBookingsList from 'components/guestBookings/GuestBookingsList';
import './GuestBookings.css';

const GuestBookings: React.FC = () => {
    return (
        <Layout id="guest-bookings">
            <BackArrow pageTitle="Guest Bookings" />
            <IonContent>
                <GuestBookingsList />
            </IonContent>
        </Layout>
    );
};

export default GuestBookings;
