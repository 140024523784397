import { types } from 'mobx-state-tree';

const Brand = types
    .model('Brand', {
        resort_id: types.optional(types.identifierNumber, () => -1),
        name: types.optional(types.string, () => ''),
        subdomain: types.optional(types.string, () => ''),
        welcome: types.optional(types.string, () => '/assets/solay-tagline.png'),
        logo: types.optional(types.string, () => '/assets/logo/logotype-solay-white.png'),
        logo_square: types.optional(types.string, () => '/assets/logo/solay-icon-avatar.png'),
        color_highlight: types.optional(types.string, () => '#f8b82b'), // Yellow buttons
        color_secondary: types.optional(types.string, () => '#2c6a65'), // Green buttons / BG
        color_background: types.optional(types.string, () => '#dddddd'), // Welcome screen background
        timeslot: types.optional(types.string, () => 'default'), // or 'simple'
        email: types.optional(types.string, () => 'help@solayapp.com'),
    })
    .views((self) => ({
        getTimeSlots() {
            return self.timeslot === 'simple' ? ['morning', 'afternoon'] : ['all day', 'morning', 'afternoon'];
        },
        isCustom() {
            return self.resort_id > 0;
        },
    }));

export default Brand;
