import * as React from 'react';
import { PropsWithChildren } from 'react';
import { IonLabel, IonItem, IonButton, IonIcon } from '@ionic/react';

import './PrimaryLabel.css';

const PrimaryLabel: React.FC<PropsWithChildren<any>> = (props) => (
    <IonItem id="label-component" className="rounded-full">
        <IonButton fill="clear" slot={props.iconSlot}>
            <IonIcon icon={props.iconName} />
        </IonButton>
        <IonLabel>{props.children}</IonLabel>
    </IonItem>
);

export default PrimaryLabel;
