import * as React from 'react';
import { IonCardContent, IonCardHeader, IonCardSubtitle, IonCardTitle, IonCard, IonButton } from '@ionic/react';
import { useHistory } from 'react-router';
import { observer } from 'mobx-react';
import moment from 'moment';

import { rootState } from 'models/RootState';
import './ReservationCard.css';

interface Props {
    resortName: string | null | undefined;
    description: string | null;
    schedule: string;
    date: string | null;
    image: string | null | undefined;
    status: string | null;
    id: string;
}

const ReservationCard: React.FC<Props> = (props) => {
    const history = useHistory();
    const reservationId = parseInt(props.id, 10);
    const reservationDate = moment(props.date, 'YYYY-MM-DD').format('MM/DD/YYYY');
    const isPastDay = moment().isAfter(reservationDate, 'day');

    const viewReservationById = (reservationId: number): void => {
        rootState.setReservationById(reservationId);
        // This prevents setResort and setResortArea from using previously set
        // data before setReservationById above completes
        setTimeout(() => {
            if (rootState.currentReservation?.resort?.id) {
                rootState.setResort(rootState.currentReservation?.resort?.id);
            }
            if (rootState.currentReservation?.beach_pool) {
                rootState.setResortArea(rootState.currentReservation?.beach_pool);
            }
            setTimeout(() => {
                const resortId = rootState.currentReservation?.resort?.id;

                !isPastDay ? history.push('/reservation') : history.push('/resort/' + resortId);
            }, 100);
        }, 100);
    };

    return (
        <IonCard className="reservation-card">
            <IonCardContent>
                <img src={props.image || '/assets/mocks/resort-map.png'} alt="" />
            </IonCardContent>
            <IonCardHeader>
                <IonCardTitle>{props.resortName}</IonCardTitle>
                <IonCardSubtitle>{props.description}</IonCardSubtitle>
                <IonCardSubtitle>{props.schedule}</IonCardSubtitle>
                <IonCardSubtitle>reservation date {reservationDate}</IonCardSubtitle>
                <IonButton
                    onClick={() => viewReservationById(reservationId)}
                    className="reservation-status-button"
                    color="green"
                >
                    {isPastDay ? 'Reserve Again' : props.status}
                </IonButton>
            </IonCardHeader>
        </IonCard>
    );
};

export default observer(ReservationCard);
