import * as React from 'react';
import { useState, useEffect } from 'react';
import { API_HOST } from 'AppConstants';
import { IonContent, IonItem, IonLabel, IonList, IonMenu, IonMenuToggle, IonToggle } from '@ionic/react';
import { useLocation } from 'react-router-dom';
import cx from 'classnames';

import { rootState } from 'models/RootState';
import Logo from 'components/solayLogo/Logo';
import './Menu.css';
import { Deploy } from 'cordova-plugin-ionic';
import { errorReporter } from 'services/ErrorReporter';

interface AppPage {
    url?: string;
    title: string;
    pageTitle?: string;
    content?: string;
    hideForResortView?: boolean;
}

const appPages: AppPage[] = [
    {
        title: 'reservations',
        url: '/reservations',
        hideForResortView: true,
    },
    {
        title: 'messages',
        url: '/messages',
        hideForResortView: true,
    },
    {
        title: 'profile',
        url: '/profile',
    },
    {
        // @TODO look into this to see if we can link to the settings for our app, see `application_details`: https://ionicframework.com/docs/native/open-native-settings
        title: 'notifications',
        pageTitle: 'Notifications',
        url: '/page/notifications',
        hideForResortView: true,
    },
    {
        title: 'terms & conditions',
        url: '/page/terms-and-conditions',
        pageTitle: 'Terms & Conditions',
    },
    {
        title: 'privacy policy',
        url: '/page/privacy-policy',
        pageTitle: 'Privacy Policy',
    },
    {
        title: 'terms of use',
        url: '/page/terms-of-use',
        pageTitle: 'Terms of Use',
    },
    {
        title: 'about',
        url: '/page/about',
        pageTitle: 'About',
    },
];

const Menu: React.FC = () => {
    const location = useLocation();
    const [activeMenu, setActiveMenu] = useState(true);
    const [activeGesture, setActiveGesture] = useState(false);
    const [currentVersion, setCurrentVersion] = useState('');
    const [currentChannel, setCurrentChannel] = useState('');
    const [currentBuildId, setCurrentBuildId] = useState('');

    useEffect(() => {
        if (location.pathname === '/search') {
            setActiveMenu(false);
            setActiveGesture(true);
        }
    }, [location.pathname]);

    Deploy.getCurrentVersion()
        .then((currentVersion) => {
            if (currentVersion?.binaryVersionName) setCurrentVersion('v' + currentVersion.binaryVersionName);
            if (currentVersion?.buildId) setCurrentBuildId(currentVersion.buildId);
            if (currentVersion?.channel) setCurrentChannel(currentVersion.channel);

            // Configure Rollbar with build and user details
            errorReporter.client.configure({
                payload: {
                    person: {
                        email: rootState.user?.email,
                        name: rootState.user?.name,
                        binaryVersionName: 'v' + currentVersion?.binaryVersionName,
                        buildId: currentVersion?.buildId,
                        channel: currentVersion?.channel,
                    },
                },
            });
        })
        .catch((error) => {
            errorReporter.error('Deploy.getCurrentVersion', error);
        });

    return (
        <IonMenu
            className="mt-safe"
            swipeGesture={activeGesture}
            disabled={activeMenu}
            contentId="main"
            type="overlay"
            auto-hide="false"
        >
            <IonContent>
                <IonList id="menu">
                    {appPages.map((appPage, index) => {
                        return (
                            <IonMenuToggle key={index} autoHide={false}>
                                <IonItem
                                    className={cx(
                                        {
                                            selected: location.pathname === appPage.url,
                                            hidden: appPage.hideForResortView && rootState.isResortView(),
                                        },
                                        'font-normal',
                                    )}
                                    routerLink={
                                        appPage.pageTitle !== undefined
                                            ? appPage.url + '/' + appPage.pageTitle
                                            : appPage.url
                                    }
                                    routerDirection="none"
                                    lines="none"
                                    detail={false}
                                >
                                    <IonLabel>{appPage.title}</IonLabel>
                                </IonItem>
                            </IonMenuToggle>
                        );
                    })}
                    <IonMenuToggle autoHide={true}>
                        <IonItem
                            detail={false}
                            routerLink="/logout"
                            lines="none"
                            routerDirection="none"
                            className="font-normal"
                        >
                            <IonLabel>logout</IonLabel>
                        </IonItem>
                    </IonMenuToggle>
                </IonList>

                <div className="menu-logo-container">
                    <Logo logoSrc={rootState.brand.logo} />
                </div>
                <div>
                    <p className="text-center text-xs text-light">
                        {currentVersion}
                        <br />
                        {currentChannel}
                        <br />
                        {currentBuildId}
                        <br />
                        {API_HOST}
                    </p>
                </div>
            </IonContent>
        </IonMenu>
    );
};

export default Menu;
