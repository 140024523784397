import axios, { AxiosInstance, AxiosPromise } from 'axios';

import { API_ENDPOINT } from 'AppConstants';
import { LoginRequest } from 'models/LoginRequest';
import { RegisterRequest } from 'models/RegisterRequest';
import { UpdateProfileRequest } from 'models/UpdateProfileRequest';
import { rootState } from 'models/RootState';
import { OrderCreateRequest } from 'models/OrderCreateRequest';
import { OrderUpdateRequest } from 'models/OrderUpdateRequest';
import { SendMessageRequest } from 'models/SendMessageRequest';
import { errorReporter } from './ErrorReporter';

/**
 * Solay API Service Singleton
 *
 * Add all calls to the SOlay API methods here.
 *
 * Example
 * <code>
 * import { solayAPI } from 'services/SolayAPI';
 * solayAPI.search('hilton').then(handleSearchRequestSuccess, handleSearchRequestError);
 * </code>
 */
class SolayAPI {
    private static _instance: SolayAPI;

    public client: AxiosInstance;

    public successCode = 0;

    private constructor(baseUrl = API_ENDPOINT) {
        this.client = axios.create({
            baseURL: baseUrl,
        });

        this._configureAxios();
    }

    public static getInstance() {
        return this._instance || (this._instance = new SolayAPI());
    }

    /**
     * Configure Axios:
     * - add Auth Headers if logged in
     */
    private _configureAxios() {
        // Add the Auth header if available
        this.client.interceptors.request.use((config) => {
            if (config.baseURL === API_ENDPOINT && !config.headers.Authorization) {
                const token = rootState.getAPIToken();
                if (token) {
                    config.headers.Authorization = `Bearer ${token}`;
                }
                if (rootState.brand.subdomain) {
                    config.headers['X-Brand'] = rootState.brand.subdomain;
                }
            }

            return config;
        });

        // Send error messages to Rollbar
        this.client.interceptors.response.use(
            (response) => {
                // Any status code that lie within the range of 2xx cause this function to trigger
                // Do something with response data

                // Log non success messages.
                // This would be quite noisey so leaving off.
                // if (response.data.code && response.data.code !== this.successCode) {
                //     errorReporter.warn('Solay API non-success', response);
                // }

                return response;
            },
            function (error) {
                // Any status codes that falls outside the range of 2xx cause this function to trigger

                // The data to send to Rollbar.
                const logData = {
                    response: null,
                    request: null,
                    message: null,
                    config: error.config,
                };

                let isUnauthorized = false;

                // subject of the error message.
                let logMessage = 'Solay API ' + error.config.url;

                if (error.response) {
                    // The request was made and the server responded with a status code
                    // that falls out of the range of 2xx

                    // console.log(error.response.data);
                    // console.log(error.response.status);
                    // console.log(error.response.headers);
                    const { status, data } = error.response;

                    const UNAUTHORIZED = 401;
                    if (status === UNAUTHORIZED) {
                        isUnauthorized = true;
                    }

                    // Add status to group
                    logMessage += ' ' + status;

                    // Add response and message to
                    logData.response = error.response;
                    logData.message = data.message;
                } else if (error.request) {
                    // The request was made but no response was received
                    // `error.request` is an instance of XMLHttpRequest in the browser and an instance of
                    // http.ClientRequest in node.js
                    // console.log(error.request);
                    logData.request = error.request;
                } else {
                    // Something happened in setting up the request that triggered an Error
                    // console.log('Error', error.message);
                    logData.message = error.message;
                }

                errorReporter.error(logMessage, logData);

                if (isUnauthorized) {
                    alert('Please login again.');
                    window.location.href = '/logout';
                }

                return Promise.reject(error);
            },
        );
    }

    /*******************************
     * User Resources
     *******************************/

    /**
     * User Login
     * @param data
     */
    public userLogin(data: LoginRequest): AxiosPromise {
        return this.client.post('/login', data);
    }

    /**
     * User Register
     * @param data
     */
    public userRegister(data: RegisterRequest): AxiosPromise {
        return this.client.post('/register', data);
    }

    /**
     * User Show
     * @param query
     */
    public userView(): AxiosPromise {
        return this.client.get('/profile/show');
    }

    /**
     * Update User Profile
     * @param data
     * email:
     * name:
     */
    public userUpdate(data: UpdateProfileRequest): AxiosPromise {
        return this.client.post('/profile/update', data);
    }

    /**
     * User Password Reset
     * @param email
     */
    public userPasswordReset(email: string): AxiosPromise {
        return this.client.post('/profile/resetpass', { email: email });
    }

    /**
     * Get User's Messages
     * @param id
     */
    public getMessages(id: number): AxiosPromise {
        return this.client.get('/message/listByUser/' + id);
    }

    /**
     * Get All Messages
     */
    public getAllMessages(): AxiosPromise {
        return this.client.get('/message/listAllMessages');
    }

    /**
     * Send message to support
     * @param message
     */
    public sendMessage(data: SendMessageRequest): AxiosPromise {
        return this.client.post('/message/create', data);
    }

    /**
     * Validate code when checking into a Resort
     * @param code
     */
    public userCodeValidate(code: string): AxiosPromise {
        return this.client.post('/code/validate?code=' + code);
    }

    /*******************************
     * Order Resources
     *******************************/

    /**
     * Check OR Create Order
     * @param data
     */
    public orderCreate(data: OrderCreateRequest): AxiosPromise {
        if (data.rental_type === 'all day') {
            data.rental_type = 'fullday';
        }
        return this.client.post('/reservation', data);
    }

    /**
     * Update Order By Id
     * @param data
     *
     * Data that the API accepts:
     * 'total_chairs' => 'numeric',
     * 'total_cabanas' => 'numeric',
     * 'rental_type' => 'in:' . implode(',', array_keys(Utils::$RENTAL_TYPE)),
     * 'reservation_date' => 'date',
     */
    public orderUpdate(data: OrderUpdateRequest): AxiosPromise {
        return this.client.post('/reservation/' + data.id, data);
    }

    /**
     * Update Order Status By Id
     *  @param id, status
     * Valid statuses: reserved, ready_for_checkin, checked_in, checked_out, completed, cancelled
     */
    public updateOrderStatus(id: number, status: string): AxiosPromise {
        return this.client.post('/reservation/update-status/' + id, { status: status });
    }

    /**
     * Cancel Order By Id
     * @param data
     */
    public orderCancel(id: number): AxiosPromise {
        return this.client.post('/reservation/update-status/' + id, { status: 'cancelled' });
    }

    /**
     * Get all orders by resort id.
     * Must be admin or resort admin
     */
    public ordersByResortId(id: number): AxiosPromise {
        return this.client.get('/reservations/' + id);
    }

    /*******************************
     * Resort Resources
     *******************************/

    /**
     * Get All Resorts
     */
    public loadResorts(): Promise<AxiosPromise> {
        return this.client.get('/resorts');
    }

    /**
     * Resort Search
     * @param query
     */
    public resortSearch(query = ''): AxiosPromise {
        return this.client.get('/resorts/search?name=' + encodeURI(query));
    }

    /*******************************
     * Brand Resources
     *******************************/

    /**
     * Get All Brands
     */
    public loadBrands(): Promise<AxiosPromise> {
        return this.client.get('/brands');
    }
}

export const solayAPI = SolayAPI.getInstance();
