import * as React from 'react';
import { Redirect, Route, RouteComponentProps } from 'react-router-dom';
import { any } from 'prop-types';

import Login from '../pages/login/Login';
import { rootState } from 'models/RootState';

type Props = {
    component: (ReturnType<typeof any> & React.ComponentType<RouteComponentProps<any>>) | React.ComponentType<any>;
    path?: string | string[];
    exact?: boolean;
};

/**
 *
 * @param param0
 */
const PrivateRoute: React.FC<Props> = ({ component: Component, ...props }) => {
    const isLoggedIn = rootState.user?.isLoggedIn() ?? false;

    // if i have the login path, handle it differently...
    if (props.path === '/login') {
        return isLoggedIn ? <Redirect to="/search" /> : <Route path="/login" component={Login} />;
    }

    return (
        <Route
            {...props}
            render={(innerProps) => (isLoggedIn ? <Component {...innerProps} /> : <Redirect to="/welcome" />)}
        />
    );
};

export default PrivateRoute;
