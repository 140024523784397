import * as React from 'react';
import './InputDatePicker.css';
import { DatePicker } from '@material-ui/pickers';
import { MaterialUiPickersDate } from '@material-ui/pickers/typings/date';
import { IonButton } from '@ionic/react';
import { InputAdornment, IconButton } from '@material-ui/core';
import CalendarTodayIcon from '@material-ui/icons/CalendarTodayTwoTone';
import { rootState } from 'models/RootState';
import { forEach, includes } from 'lodash';
import moment from 'moment';

interface InputDatePickerProps {
    value?: MaterialUiPickersDate;
    onChange: (arg0: MaterialUiPickersDate) => void;
    disabled: boolean;
}

/**
 * See https://material-ui-pickers.dev/api/DatePicker
 * @param value
 * @param onChange
 * @constructor
 */
const InputDatePicker: React.FC<InputDatePickerProps> = ({ value, onChange, disabled }) => {
    const blackoutDays: string[] = [];

    const setBlackoutDays = () => {
        forEach(rootState.currentResort?.blackout_dates, (blackoutDay) => {
            blackoutDays.push(moment(blackoutDay).format('YYYY-MM-DD'));
        });
    };

    setBlackoutDays();

    const disabledDates = (date: MaterialUiPickersDate) => {
        return includes(blackoutDays, moment(date).format('YYYY-MM-DD'));
    };

    return (
        <IonButton color="gold" disabled={disabled} className="w-full rounded-full calendar-btn">
            <DatePicker
                className="button"
                value={value}
                autoOk={true}
                onChange={onChange}
                cancelLabel={null}
                disabled={disabled}
                disablePast={true}
                shouldDisableDate={disabledDates}
                InputProps={{
                    startAdornment: value ? null : <div className="ml-4 text-center">choose a reservation date</div>,
                    endAdornment: (
                        <InputAdornment position="end">
                            <IconButton>
                                <CalendarTodayIcon />
                            </IconButton>
                        </InputAdornment>
                    ),
                }}
            />
        </IonButton>
    );
};

export default InputDatePicker;
