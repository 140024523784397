import * as React from 'react';
import { IonItem, IonGrid, IonCol, IonRow } from '@ionic/react';
import Order from 'models/Order';
import './GuestBookingsListItemProps.css';

export interface GuestBookingsListItemProps {
    order: typeof Order.Type;
}

const GuestBookingsListItem: React.FC<GuestBookingsListItemProps> = (props) => {
    return (
        <IonItem>
            <IonGrid>
                <IonRow>
                    <IonCol size="2">
                        <img src={props.order.getIconByStatus()} alt="" />
                    </IonCol>
                    <IonCol className="guest-booking-list guest-booking-list-item-props">
                        <h1>
                            {props.order.getSchedule().toLowerCase()} - {props.order.getDescription()}
                        </h1>
                        <h1>status: {props.order?.status}</h1>
                    </IonCol>
                </IonRow>
                <IonRow>
                    <IonCol size="2">
                        <img src="/assets/icon/mail.png" alt="" />
                    </IonCol>
                    <IonCol className="guest-booking-list guest-booking-list-item-contact">
                        <h1>
                            <a href={'mailto:' + props.order?.email}>{props.order?.email}</a>
                        </h1>
                    </IonCol>
                </IonRow>
            </IonGrid>
        </IonItem>
    );
};

export default GuestBookingsListItem;
