import * as React from 'react';
import { Route, Redirect } from 'react-router-dom';
import { IonApp, IonRouterOutlet, IonSplitPane, setupConfig } from '@ionic/react';
import { IonReactRouter } from '@ionic/react-router';
import { observer } from 'mobx-react';
import { applySnapshot } from 'mobx-state-tree';
import Page from './pages/Page';
import Menu from './components/Menu';

// Core CSS required for Ionic components to work properly
import '@ionic/react/css/core.css';

// Basic CSS for apps built with Ionic
import '@ionic/react/css/normalize.css';
import '@ionic/react/css/structure.css';
import '@ionic/react/css/typography.css';

// Optional CSS utils that can be commented out
import '@ionic/react/css/padding.css';
import '@ionic/react/css/float-elements.css';
import '@ionic/react/css/text-alignment.css';
import '@ionic/react/css/text-transformation.css';
import '@ionic/react/css/flex-utils.css';
import '@ionic/react/css/display.css';

// Theme variables
import './theme/fonts.css';
import './theme/variables.css';
import './theme/components.css';
import './theme/shared.css';

// Utility classes
import './theme/utilities.css';

/** Begin pages */
import ReservationsListPage from './pages/reservations/ReservationsList';
import SearchPage from './pages/search/Search';
import ResortPage from './pages/resort/Resort';
import LoginPage from './pages/login/Login';
import ForgotPage from './pages/forgot/Forgot';
import SignupPage from './pages/signup/Signup';
import WelcomePage from './pages/welcome/Welcome';
import SupportPage from './pages/support/Support';
import Conversations from './pages/Conversations/Conversations';

import { MuiPickersUtilsProvider } from '@material-ui/pickers';
import MomentUtils from '@date-io/moment';
import { StorageService } from './services/StorageService';
import Profile from './pages/profile/Profile';
import { rootState } from 'models/RootState';
import Messages from './pages/messages/Messages';
import GuestBookings from './pages/guestBookings/GuestBookings';
import PrivateRoute from './components/PrivateRoute';
import ReservationPage from 'pages/reservations/Reservation';
import { Plugins } from '@capacitor/core';
import Loading from 'pages/loading/Loading';
import Branding from 'Branding';

const { SplashScreen } = Plugins;

const App: React.FC = () => {
    SplashScreen.hide();

    setupConfig({
        mode: 'ios',
    });

    return (
        <MuiPickersUtilsProvider utils={MomentUtils}>
            <IonApp>
                <Branding />
                <IonReactRouter>
                    <IonSplitPane contentId="main">
                        <Menu />
                        <IonRouterOutlet id="main">
                            <Route path="/" component={Loading} exact />
                            <Route path="/welcome" component={WelcomePage} exact />
                            <Route path="/login" component={LoginPage} exact />
                            <Route path="/forgot" component={ForgotPage} exact />
                            <Route path="/signup" component={SignupPage} exact />
                            <Route path="/page/:name" component={Page} exact />
                            <Route path="/page/:name/:pageTitle" component={Page} exact />
                            <Route path="/page/:name/:pageTitle/:signup" component={Page} exact />
                            <Route
                                path="/logout"
                                render={() => {
                                    applySnapshot(rootState, {});
                                    new StorageService().clear();
                                    // @TODO close menu
                                    return <Redirect to="/" />;
                                }}
                            />

                            <PrivateRoute path="/profile" component={Profile} />
                            <PrivateRoute path="/resort/:resortId" component={ResortPage} exact />
                            <PrivateRoute path="/reservations" component={ReservationsListPage} exact />
                            <PrivateRoute path="/reservation" component={ReservationPage} exact />
                            <PrivateRoute path="/messages" component={Messages} exact />
                            <PrivateRoute path="/guestBookings" component={GuestBookings} exact />
                            <PrivateRoute path="/search" component={SearchPage} exact />
                            <PrivateRoute path="/support" component={SupportPage} exact />
                            <PrivateRoute path="/conversations" component={Conversations} exact />
                        </IonRouterOutlet>
                    </IonSplitPane>
                </IonReactRouter>
            </IonApp>
        </MuiPickersUtilsProvider>
    );
};

export default observer(App);
