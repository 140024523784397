import { types } from 'mobx-state-tree';
import { isEmpty } from 'lodash';

import Resort from './Resort';

const User = types
    .model('User', {
        id: types.identifierNumber,
        api_token: types.maybeNull(types.string),
        name: types.maybeNull(types.string),
        email: types.maybeNull(types.string),
        avatarUrl: types.maybeNull(types.string),
        mobile_no: types.maybeNull(types.string),
        device_token: types.maybeNull(types.string),
        profile_img: types.maybeNull(types.string),
        is_admin: types.maybeNull(types.integer),
        is_staff: types.maybeNull(types.integer),
        is_super_admin: types.maybeNull(types.integer),
        is_resort_admin: types.maybeNull(types.integer),
        verified_till: types.maybeNull(types.string),
        resort: types.maybeNull(Resort),
        // We are handling this as rootState.orders
        // orders: types.array(Order),
    })
    .views((self) => ({
        isLoggedIn() {
            return !isEmpty(self);
        },
        isStaff() {
            return self.is_staff === 1;
        },
        isSuperAdmin() {
            return self.is_super_admin === 1;
        },
        isAdmin() {
            return self.is_admin === 1;
        },
        isResortAdmin() {
            return self.is_resort_admin === 1;
        },
        verifiedTill() {
            return self.verified_till;
        },
        getAvatar() {
            return self.profile_img ?? '/assets/icon/avatar.jpg';
        },
        greeting() {
            const name = self?.name ? ' ' + self?.name : '';
            return 'Hello my dearest' + name + '!';
        },
    }));

export default User;
