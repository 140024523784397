import Rollbar from 'rollbar';
import { ROLLABAR_ACCESS_TOKEN, NODE_ENV } from 'AppConstants';

/**
 * Error reporting service
 *
 * Configures a Rollbar singleton and proxies to commont logging.
 *
 * See JS Docs at the bottom of this file.
 */
class ErrorReporter {
    private static _instance: ErrorReporter;
    public client: Rollbar;

    private constructor(config: Rollbar.Configuration) {
        this.client = new Rollbar(config);
    }

    public static getInstance(config: Rollbar.Configuration) {
        return this._instance || (this._instance = new ErrorReporter(config));
    }

    /**
     * The following log, debug, info, warn, warning, errror and critial methods are proxies to the rollbar client but we log to the console.
     */

    /**
     * proxy to Rollbar.log()
     * @param args
     *
     * @example
     * errorReporter.log('log message');
     * errorReporter.log('log message about date', new Date());
     * errorReporter.log('log message about array', ['foo', 'bar']);
     * errorReporter.log('log message about object', { foo: 'bar' });
     * errorReporter.log('log message about error', error);
     */
    public log(...args: Rollbar.LogArgument[]): Rollbar.LogResult {
        console.log(...args);
        return this.client.log(...args);
    }

    /**
     * proxy to Rollbar.debug()
     * @param args
     *
     * @example
     * errorReporter.debug('debug message');
     * errorReporter.debug('debug message about date', new Date());
     * errorReporter.debug('debug message about array', ['foo', 'bar']);
     * errorReporter.debug('debug message about object', { foo: 'bar' });
     * errorReporter.debug('debug message about error object', error);
     */
    public debug(...args: Rollbar.LogArgument[]): Rollbar.LogResult {
        console.debug(...args);
        return this.client.debug(...args);
    }

    /**
     * proxy to Rollbar.info()
     * @param args
     *
     * @example
     * errorReporter.info('info message');
     * errorReporter.info('info message about date', new Date());
     * errorReporter.info('info message about array', ['foo', 'bar']);
     * errorReporter.info('info message about object', { foo: 'bar' });
     * errorReporter.info('info message about error object', error);
     */
    public info(...args: Rollbar.LogArgument[]): Rollbar.LogResult {
        console.info(...args);
        return this.client.info(...args);
    }

    /**
     * proxy to Rollbar.warn()
     * @param args
     *
     * @example
     * errorReporter.warn('warn message');
     * errorReporter.warn('warn message about date', new Date());
     * errorReporter.warn('warn message about array', ['foo', 'bar']);
     * errorReporter.warn('warn message about object', { foo: 'bar' });
     * errorReporter.warn('warn message about error object', error);
     */
    public warn(...args: Rollbar.LogArgument[]): Rollbar.LogResult {
        console.warn(...args);
        return this.client.warn(...args);
    }

    /**
     * proxy to Rollbar.warning()
     * @param args
     *
     * @example
     * errorReporter.warning('warning message');
     * errorReporter.warning('warning message about date', new Date());
     * errorReporter.warning('warning message about array', ['foo', 'bar']);
     * errorReporter.warning('warning message about object', { foo: 'bar' });
     * errorReporter.warning('warning message about error object', error);
     */
    public warning(...args: Rollbar.LogArgument[]): Rollbar.LogResult {
        console.warn(...args);
        return this.client.warning(...args);
    }

    /**
     * proxy to Rollbar.error()
     * @param args
     *
     * @example
     * errorReporter.error('error message');
     * errorReporter.error('error message about date', new Date());
     * errorReporter.error('error message about array', ['foo', 'bar']);
     * errorReporter.error('error message about object', { foo: 'bar' });
     * errorReporter.error('error message about error object', error);
     */
    public error(...args: Rollbar.LogArgument[]): Rollbar.LogResult {
        console.error(...args);
        return this.client.error(...args);
    }

    /**
     * proxy to Rollbar.critical()
     * @param args
     *
     * @example
     * errorReporter.critical('critical message');
     * errorReporter.critical('critical message about date', new Date());
     * errorReporter.critical('critical message about array', ['foo', 'bar']);
     * errorReporter.critical('critical message about object', { foo: 'bar' });
     * errorReporter.critical('critical message about error object', error);
     */
    public critical(...args: Rollbar.LogArgument[]): Rollbar.LogResult {
        console.error(...args);
        return this.client.critical(...args);
    }
}

const config: Rollbar.Configuration = {
    accessToken: ROLLABAR_ACCESS_TOKEN,
    captureUncaught: true,
    captureUnhandledRejections: true,
    payload: {
        environment: NODE_ENV,
    },
};

/**
 * Error reporter, currently using Rollbar as a client.
 *
 * @example <caption>import</caption>
 * import { errorHandler } from 'services/ErrorReporter';
 *
 * @example <caption>report to Rollbar</caption>
 * errorHandler.client.log('Log message about object', { foo: 'bar' });
 * errorHandler.client.log('Log message', [ new Date(), { foo: 'bar' }]);
 * errorHandler.client.critical('Critical error message about error object', errorObject);
 *
 * @example <caption>report to Rollbar and the console</caption>
 * // If you'd like to also log to the console you can use the proxies by removing the `.client`:
 * errorHandler.log('Log message about object', { foo: 'bar' });
 * errorHandler.log('Log message', [ new Date(), { foo: 'bar' }]);
 * errorHandler.critical('Critical error message about error object', errorObject);
 */
export const errorReporter = ErrorReporter.getInstance(config);
