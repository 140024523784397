import * as React from 'react';
import { IonContent, IonRow, IonCol } from '@ionic/react';

import Layout from 'components/Layout';
import BackArrow from 'components/backArrow/BackArrow';

const Support: React.FC = () => {
    return (
        <Layout>
            <BackArrow pageTitle="Support" />
            <IonContent>
                <IonRow>
                    <IonCol>
                        <div className="container">
                            <p className="font-bold">How do I find my resort?</p>
                            <p>
                                Search for your resort in the search tool bar after logging in to the Solay app. If your
                                resort is not found by the search tool, double check the name and spelling. Only
                                participating resorts are listed. Otherwise, check back soon! New resorts are being
                                added to Solay. Also, feel free to ask your resort to provide the Solay service!
                            </p>
                            <br />
                            <p className="font-bold">Can I add chairs after my initial reservation?</p>
                            <p>
                                Yes! You can always add chairs, up to 4 per room reservation, space permitting. We can’t
                                guarantee that chairs added to your reservation the same day as your Solay check in will
                                be grouped with your originally reserved chairs, but we’ll try our best!
                            </p>
                            <br />

                            <p className="font-bold">
                                I have multiple room reservations, how do I reserve chairs for each reservation?
                            </p>
                            <p>
                                Contact us if you have questions about reserving chairs for multiple room reservations
                                and we will walk you through it!
                            </p>
                            <br />

                            <p className="font-bold">Can I add days to my reservation?</p>
                            <p>
                                Yes! You can always add days, even once you have arrived, space permitting. Your resort
                                code is good throughout your length of stay. Only one resort code is needed, even if you
                                add new Solay reservations during your stay.
                            </p>
                            <br />
                            <p className="font-bold">I don’t want to reserve 4 hours, can I reserve for less time?</p>
                            <p>
                                No, four hours is the minimum reservation. If you are finished and check out of your
                                chairs (optional) before your reservation period is complete, you will not be refunded
                                any portion of the reservation fee.
                            </p>
                            <br />
                            <p className="font-bold">How do I find my reserved chairs?</p>
                            <p>
                                The app tells you were your chairs are located, after you fully check in to your
                                reservation. When you arrive at the pool or beach, check in to your reservation on the
                                Solay app. During check in, after you see &quot;your code was successfully
                                validated&quot; tap the &quot;complete check-in&quot; button. The app will show your
                                reserved seats and seat numbers with blinking yellow umbrellas marked with numbers. The
                                seat numbers shown correspond to numbers on Solay chair markers on each chair (markers
                                are placed on each chair by resort staff).
                            </p>
                            <p>
                                If you are still having trouble locating your chair, pool or beach resort staff may be
                                able to assist you.
                            </p>
                            <br />
                            <p className="font-bold">What time do I need to check into my reserved chairs?</p>
                            <p>10:30 for morning and full day reservations, 1:30 for afternoon reservations</p>
                            <br />
                            <p className="font-bold">How do I check-in to my reserved chairs?</p>
                            <p>
                                When you arrive at the pool or beach, check in to your reservation on your Solay app
                                through the Reservations tab. Tap the &quot;check in&quot; button for the resort you
                                wish to check in to. The Solay app will then ask for your resort code. Copy and paste
                                your resort code from your Solay text message. Once your code is successfully validated,
                                tap the &quot;complete check-in&quot; button. The app will show your reserved seats and
                                seat numbers with blinking yellow umbrellas marked with numbers. The seat numbers shown
                                correspond to numbers on Solay chair markers on each chair (markers are placed on each
                                chair by resort staff).
                            </p>
                            <br />
                            <p className="font-bold">
                                I want to check-in but I am not at the pool/beach area, what should I do?
                            </p>
                            <p>
                                You must be at the pool/beach area to check in to your Solay reservation. Location
                                services on your mobile phone verifies your location.
                            </p>
                            <br />
                            <p className="font-bold">Where do I find my resort code?</p>
                            <p>
                                Your resort code is delivered in a text message from Solay to the mobile number provided
                                at room check in. If someone else checked in to the resort for you, that person would
                                receive the resort code. Your resort should have notified Solay when you checked in so
                                that we could send you the code.
                            </p>
                            <br />
                            <p>
                                If you did not receive a text with your resort code, and nobody else checked in for you,
                                please see the front desk staff. Your code will be sent upon their request (codes are
                                only for hotel guests).
                            </p>
                            <br />
                            <p className="font-bold">Can I copy and paste my resort code?</p>
                            <p>
                                Yes! You should copy and paste your resort code into the Solay app when you check in to
                                your Solay reservation.
                            </p>
                            <br />
                            <p className="font-bold">What is the resort code for?</p>
                            <p>
                                The resort code is designed to ensure the Solay service is used only by registered hotel
                                guests.
                            </p>
                            <br />
                            <p className="font-bold">How long is my resort code good for?</p>
                            <p>
                                Your resort code is good throughout your length of stay. Only one resort code is needed,
                                even if you add new Solay reservations during your stay.
                            </p>
                            <br />
                            <p className="font-bold">I forgot to check in, what should I do?</p>
                            <p>
                                You will not be able to check in after 10:30 for morning and full day reservations, or
                                after 1:30 for afternoon reservations. Solay will send you reminders to check in. If you
                                don’t check in, you will forfeit your spot and reservation fee, and someone else with a
                                Solay reservation may be given that spot. We don’t want that to happen to you, so please
                                check in!
                            </p>
                            <br />
                            <p className="font-bold">My chair is occupied, what should I do?</p>
                            <p>
                                After completing check-in on the Solay app, and using the Solay app to locate your seat,
                                if you find your spot is occupied, we suggest you take the following steps:
                            </p>
                            <ul>
                                <li>politely greet the person sitting in your reserved spot</li>
                                <li>
                                    tell them you reserved with Solay (all chairs are marked by the resort as Solay
                                    chairs)
                                </li>
                                <li>
                                    you may want to share the Solay app seat indicator on your mobile device (much like
                                    in a movie theater or sporting event experience, except here the app is your
                                    ticket!)
                                </li>
                                <li>
                                    if the individual does not promptly relocate, you may ask resort staff for
                                    assistance
                                </li>
                            </ul>
                            <br />
                            <p className="font-bold">How do I make changes and cancellations?</p>
                            <p>Using the Solay app on your mobile device, Solay reservations may be:</p>
                            <ul>
                                <li>
                                    cancelled for a full refund up until 3 days before your reservation check in date
                                </li>
                                <li>
                                    cancellations made less than 3 days before your reservation check in date will be
                                    refunded minus a 5% fee
                                </li>
                                <li>
                                    changed to add chairs to your reservation (space permitting) anytime, however,
                                    chairs added on the same day as your Solay reservation check in day are not
                                    guaranteed to be grouped with the original reserved chairs
                                </li>
                            </ul>
                            <br />
                            <p className="font-bold">How do I sit with a group?</p>
                            <p>
                                Want to sit with your friends and family? Currently you can reserve up to 4 chairs with
                                your room reservation. Soon you will be able to link your reservation to friends’ and
                                family’s reservations, and Solay will make every effort to seat you together. Check back
                                for availability of this new feature!
                            </p>
                            <br />
                            <p className="font-bold">Is check out required?</p>
                            <p>
                                No, check out is not required. At the end of your reservation period you are
                                automatically checked out. However, if you are finished with your chairs early, by
                                checking out you allow others to reserve and enjoy the pool/beach experience. That’s a
                                considerate thing to do, and we are working on a reward system for early check out.
                            </p>
                            <br />
                            <p className="font-bold">Why are location services required?</p>
                            <p>
                                Location services are required to ensure guests are actually at the pool or beach and
                                ready to enjoy their seats when they check in to their Solay spot.
                            </p>
                            <br />
                            <p className="font-bold">Do you share my location with anyone?</p>
                            <p>
                                No, Solay will not share your location with anyone and does not track or store
                                information about your location.
                            </p>
                            <br />
                            <p>Get more help</p>
                            <p>
                                If your question has not been answered here, please contact us at
                                <a href="mailto:help@Solayapp.com"> help@Solayapp.com </a> and we will respond as soon
                                as possible.
                            </p>
                        </div>
                    </IonCol>
                </IonRow>
            </IonContent>
        </Layout>
    );
};

export default Support;
