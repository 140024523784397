import React from 'react';
import { observer } from 'mobx-react';
import { rootState } from 'models/RootState';

const Branding: React.FC = () => {
    return (
        <style>
            {`
            /** Ionic CSS Variables **/
            :root {
                /** Custom Gold **/
                --ion-color-gold: ` +
                rootState.brand?.color_highlight +
                `;
                /* 
                * -rbg not necessary?
                --ion-color-gold-rgb: 248, 184, 43;
                */
                --ion-color-gold-shade: ` +
                rootState.brand?.color_highlight +
                `;
                --ion-color-gold-tint: ` +
                rootState.brand?.color_highlight +
                `;
            
                /** Custom Green **/
                --ion-color-green: ` +
                rootState.brand?.color_secondary +
                `;
                /* 
                 * -rbg not necessary?
                --ion-color-green-rgb: 44, 106, 101; 
                */
                --ion-color-green-shade: ` +
                rootState.brand?.color_secondary +
                `;
                --ion-color-green-tint: ` +
                rootState.brand?.color_secondary +
                `;
            }
            `}
        </style>
    );
};

export default observer(Branding);
