import * as React from 'react';
import { observer } from 'mobx-react';

import { rootState } from 'models/RootState';
import ResortAreaMap from 'components/map/ResortAreaMap';

const ResortAreaView: React.FC = () => {
    const image = rootState.currentResortArea?.getVicinityMap();

    return (
        <div className="w-full border-t-gold border-b-green bg-white text-center">
            <div className="resort-area-map">
                <img src={image} alt="" className="resort-area-map-background" />
                <ResortAreaMap />
            </div>
        </div>
    );
};

export default observer(ResortAreaView);
