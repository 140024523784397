import * as React from 'react';
import { useState, PropsWithChildren } from 'react';
import {
    IonButtons,
    IonModal,
    IonItem,
    IonLabel,
    IonImg,
    IonHeader,
    IonToolbar,
    IonTitle,
    IonContent,
    IonRow,
    IonCol,
    IonIcon,
} from '@ionic/react';
import { arrowBack } from 'ionicons/icons';
import 'components/description/Description.css';

const Description: React.FC<PropsWithChildren<any>> = ({ ...props }) => {
    const [showModal, setShowModal] = useState(false);
    return (
        <>
            <IonModal cssClass="description-modal" isOpen={showModal}>
                <IonHeader>
                    <IonToolbar>
                        <IonTitle>Description</IonTitle>
                        <IonButtons onClick={() => setShowModal(false)} slot="start">
                            <IonIcon color="green" icon={arrowBack as any} />
                        </IonButtons>
                    </IonToolbar>
                </IonHeader>
                <IonContent className="bg-wave">
                    <IonRow>
                        <IonCol>
                            <div className="container">
                                <p>{props.descriptionText}</p>
                            </div>
                        </IonCol>
                    </IonRow>
                </IonContent>
            </IonModal>
            <IonItem onClick={() => setShowModal(true)} lines="none">
                <IonLabel>description</IonLabel>
                <IonImg slot="end" src="/assets/icon/icon-info.svg" />
            </IonItem>
        </>
    );
};

export default Description;
