import * as React from 'react';
import { useState } from 'react';
import { IonAlert, IonContent, IonLoading, useIonViewDidEnter } from '@ionic/react';
import { observer } from 'mobx-react';
import { isEmpty } from 'lodash';

import { LoginResponse } from 'models/LoginResponse';
import { rootState } from 'models/RootState';
import { solayAPI } from 'services/SolayAPI';
import Layout from 'components/Layout';
import BackArrow from 'components/backArrow/BackArrow';
import ReservationCard from 'components/reservations/ReservationCard';
import './ReservationsList.css';

const ReservationsListPage: React.FC = () => {
    // Clear out any reserved items
    rootState.setReservedItems([]);

    const [isLoading, setIsLoading] = useState(true);
    const [alertMessage, setAlertMessage] = useState('');

    const handleSuccess = (response: LoginResponse) => {
        if (response.status === 200) {
            rootState.setUserOrders(response.data.data.orders);
        } else {
            setAlertMessage(response.data.message ?? 'Unable to load your reservations.');
        }
        setIsLoading(false);
    };

    const handleError = (error: any) => {
        // @TODO better error handling
        setAlertMessage(error?.message ?? 'Unkown error getting reservations.');
        setIsLoading(false);
    };

    useIonViewDidEnter(() => {
        solayAPI.userView().then(handleSuccess, handleError);
    });

    return (
        <Layout id="reservations-page">
            <IonContent>
                <IonLoading
                    isOpen={isLoading}
                    onDidDismiss={() => setIsLoading(false)}
                    message={'Loading your reservations'}
                />

                <IonAlert
                    isOpen={!isEmpty(alertMessage)}
                    onDidDismiss={() => setAlertMessage('')}
                    message={alertMessage}
                    buttons={['OK']}
                    mode="ios"
                />

                <BackArrow pageTitle="Reservations" />
                {rootState.userOrders.length && !isLoading ? (
                    <div className="reservations-container">
                        {rootState.getOrdersNewestFirst().map((rsv) => {
                            return (
                                <ReservationCard
                                    key={rsv.id}
                                    id={rsv.id.toString()}
                                    date={rsv.reservation_date}
                                    schedule={rsv.getSchedule()}
                                    image={rsv.resort?.resort_image}
                                    resortName={rsv.resort?.name}
                                    status={rsv.getButtonActionByStatus()}
                                    description={rsv.getDescription()}
                                />
                            );
                        })}
                    </div>
                ) : (
                    <div>
                        <p className="no-reservations-found">No reservations found</p>
                    </div>
                )}
            </IonContent>
        </Layout>
    );
};

export default observer(ReservationsListPage);
