import * as React from 'react';
import { observer } from 'mobx-react';

import { rootState } from 'models/RootState';
import ResortAreaMapItem from 'components/map/ResortAreaMapItem';
import './ResortAreaMap.css';

const ResortAreaMap: React.FC = () => {
    // If this component is being modified, changes may be necessary in
    // `components/resort-area-editor/resort-area-editor.map.tsx` of the Resort
    // Dashboard as well.

    const items = rootState.currentResortArea?.items || [];
    const columns = rootState.currentResortArea?.scale || 5;
    let rows = 1;

    // Find the last row that contains an item and make that the total number of rows
    items.forEach(({ y }) => {
        if (y && y > rows) {
            rows = y;
        }
    });

    const columnsArray = [...Array(columns).keys()].map((index) => index + 1);
    const rowsArray = [...Array(rows).keys()].map((index) => index + 1);

    const isPulsing = rootState.reservedItems.length > 0;

    return (
        <table>
            <tbody>
                {rowsArray.map((row) => (
                    <tr key={`row${row}`}>
                        {columnsArray.map((column) => (
                            <td key={`column${column}`}>
                                <div>
                                    <ResortAreaMapItem x={column} y={row} isPulsing={isPulsing} />
                                </div>
                            </td>
                        ))}
                    </tr>
                ))}
            </tbody>
        </table>
    );
};

export default observer(ResortAreaMap);
