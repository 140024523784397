/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import { Plugins } from '@capacitor/core';
const { Storage } = Plugins;

export class StorageService {
    async setObject(key: string, value: any) {
        await Storage.set({
            key: key,
            value: JSON.stringify(value),
        });
    }

    async getObject(key: string) {
        const ret = await Storage.get({ key: key });
        return JSON.parse(ret.value || '{}');
    }

    async setItem(key: string, value: string) {
        await Storage.set({
            key: key,
            value: value,
        });
    }

    async getItem(key: string) {
        const { value } = await Storage.get({ key: key });
        return value;
    }

    async removeItem(key: string) {
        await Storage.remove({ key: key });
    }

    async keys() {
        const { keys } = await Storage.keys();
        console.log('Got keys: ', keys);
    }

    async clear() {
        await Storage.clear();
    }
}
