import * as React from 'react';
import { PropsWithChildren, useState } from 'react';
import { IonItem, IonLabel, IonImg } from '@ionic/react';
import ReactBnbGallery from 'react-bnb-gallery';
import 'react-bnb-gallery/dist/style.css';
import './Gallery.css';

const Gallery: React.FC<PropsWithChildren<any>> = ({ ...props }) => {
    const [isOpen, setIsOpen] = useState(false);
    return (
        <>
            <IonItem lines="none" onClick={() => setIsOpen(true)}>
                <IonLabel>photos</IonLabel>
                <IonImg slot="end" src="/assets/icon/icon-photos.svg" />
            </IonItem>
            <ReactBnbGallery show={isOpen} photos={props.imageUrl} onClose={() => setIsOpen(false)} />
        </>
    );
};

export default Gallery;
