import * as React from 'react';

const TermsAndConditions: React.FC = () => {
    return (
        <>
            <p>Terms and Conditions of Service</p>
            <p>
                By making a reservation, you agree to the following terms and conditions (&#8220;User Agreement&#8221;)
                of using this application and services:
            </p>
            <ul>
                <li>
                    Your confirmed reservation allows you to use the reserved chair(s) during reserved period(s), in
                    accordance with resort property/cruise ship policies and procedures;
                </li>
                <li>
                    If guest fees apply, as indicated by the reservation tool, you will be charged and pay in full at
                    the time of reservation;
                </li>
                <li>
                    You will only reserve the number of chairs you intend to use for persons, not belongings or other
                    items;
                </li>

                <li>You agree to receive push notifications (such as reminders to check in, and your resort code);</li>
                <li>
                    You will not share your resort code with persons not staying in your room or travelling with you.
                    Resort codes are confidential;
                </li>
                <li> You must check-in to your chairs and/or cabana to use your reservation;</li>
                <li>
                    You must be present at the location of your reserved area, with location services enabled on your
                    mobile device, in order to check-in at your chair(s) and/or cabana. You may disable location
                    services after check-in;
                </li>
                <li className="bold">
                    If you fail to check-in on the application to your reservation by 10:30 am for the morning
                    reservation period, or by 1:30 pm for the afternoon reservation period, you automatically forfeit
                    your reservation, reservation fee, and permission to use those chair(s) and/or cabana;
                </li>
                <li>
                    Cancellations or modifications made in compliance with policies will result in a full or partial
                    refund as per applicable policy:
                </li>
                <ul>
                    <li>No-shows and cancellations made on the same day as your reservation will be charged $25.</li>

                    <li> Reservations may be:</li>
                    <ul>
                        <li> cancelled for a full refund up until 3 days before your reservation check-in date</li>
                        <li>
                            a $10 fee will be applied to cancellations made less than 3 days before your reservation
                            check-in date
                        </li>

                        <li>
                            modified to add chairs or a cabana to your reservation (space permitting) anytime (however,
                            additions on the same day as your reservation check-in day are not guaranteed to be grouped
                            with the original reserved chairs)
                        </li>
                    </ul>
                </ul>
                <li>
                    There is no guarantee of availability outside of your reserved period(s), for example if you want to
                    add the afternoon to a morning reservation once you are already seated, your same chair or cabana
                    (or any chair or cabana) may be unavailable;
                </li>
                <li>
                    You will promptly relocate yourself and your belongings at the time your reservation period ends;
                </li>
                <li>
                    You agree to the use of a third party secure payment gateway operated by Stripe™ to process payment
                    transactions - You agree to Stripe’s policies for payment transactions you request, accessible here:{' '}
                    <a href="https://stripe.com/privacy" target="_blank" rel="noopener noreferrer">
                        https://stripe.com/privacy
                    </a>
                    .
                </li>
                <li> We reserve the right to deactivate your account if you violate this User Agreement.</li>
            </ul>
        </>
    );
};

export default TermsAndConditions;
