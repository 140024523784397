import * as React from 'react';
import { useState, FormEvent } from 'react';
import { CardElement, useStripe, useElements } from '@stripe/react-stripe-js';
import { rootState } from 'models/RootState';
import { isNil } from 'lodash';
import { IonButton, IonButtons, IonContent, IonModal, IonTitle, IonToolbar } from '@ionic/react';
import './PaymentFormModal.css';
import { errorReporter } from 'services/ErrorReporter';

interface PaymentFormProps {
    showModal: boolean;
    setShowModal: React.Dispatch<React.SetStateAction<boolean>>;
    onHasStripeToken: any;
}

const PaymentFormModal: React.FC<PaymentFormProps> = (props) => {
    const [isDisabled, setIsDisabled] = useState<boolean>(false);

    const stripe = useStripe();
    const elements = useElements();

    const cardOptions = {
        style: {
            base: {
                fontSize: '20px',
                fontFamily: 'Work Sans, Roboto, Open Sans, Segoe UI, sans-serif',
            },
        },
    };

    const handleSubmit = async (event: FormEvent) => {
        // Block native form submission.
        event.preventDefault();

        // Prevent the order from being submitted twice
        setIsDisabled(true);

        if (!stripe || !elements) {
            alert('Error loading payment form');
            setIsDisabled(false);
            // Stripe.js has not loaded yet. Make sure to disable
            // form submission until Stripe.js has loaded.
            return;
        }

        // Get a reference to a mounted CardElement. Elements knows how
        // to find your CardElement because there can only ever be one of
        // each type of element.
        const cardElement = elements.getElement(CardElement);

        if (cardElement) {
            const { error, token } = await stripe.createToken(cardElement);

            // @TODO better error handling
            if (error) {
                errorReporter.error('Error creating Stripe Token', error);
                alert(error.message);
            } else {
                // @TODO Do we know if the charge is declined here? If so no need to setStripeToken().
                if (!isNil(token)) {
                    rootState.setStripeToken(token.id);
                    props.onHasStripeToken();
                }
            }
        } else {
            const msg = 'Unable to find credit card form';
            errorReporter.error(msg);
            alert(msg);
        }
        setIsDisabled(false);
    };

    return (
        <IonModal isOpen={props.showModal} cssClass="payment-form-modal mt-safe">
            <IonToolbar>
                <IonButtons slot="start">
                    <IonButton onClick={() => props.setShowModal(false)}>Cancel</IonButton>
                </IonButtons>
                <IonTitle>Add Card</IonTitle>
                <IonButtons slot="end">
                    <IonButton onClick={handleSubmit} disabled={isDisabled}>
                        Done
                    </IonButton>
                </IonButtons>
            </IonToolbar>
            <IonContent>
                <form onSubmit={handleSubmit}>
                    <fieldset className="FormGroup">
                        <div className="FormRow">
                            <CardElement options={cardOptions} />
                        </div>
                    </fieldset>
                    {/* This hidden submit needs to be here to get the form submition when you hit Enter  */}
                    <input type="submit" className="hidden" />
                </form>
            </IonContent>
        </IonModal>
    );
};

export default PaymentFormModal;
