import * as React from 'react';
import cx from 'classnames';
import { observer } from 'mobx-react';
import { isNil } from 'lodash';

import { rootState } from 'models/RootState';
import { chair, cabana } from 'components/Icons';

interface Props {
    x: number;
    y: number;
    isPulsing: boolean;
}

const ResortAreaMapItem: React.FC<Props> = ({ x, y, isPulsing }) => {
    // If this component is being modified, changes may be necessary in
    // `components/resort-area-editor/resort-area-editor.map.tsx` of the Resort
    // Dashboard as well.

    const items = rootState.currentResortArea?.items || [];
    const item = items.find((item) => item.x === x && item.y === y);
    const type = item && item.type ? item.type : null;

    const isActive = !isNil(item) && rootState.reservedItems.includes(item.id);

    let icon = null;
    switch (type) {
        case 'cabana':
            icon = cabana();
            break;
        case 'chair':
            icon = chair();
            break;
        default:
            break;
    }

    return (
        <div className={cx(!isPulsing || isActive ? 'color' : 'grayscale', isActive && 'animate-pulse')}>
            {item && isPulsing && isActive ? <span>{item.id}</span> : null}
            {icon}
        </div>
    );
};

export default observer(ResortAreaMapItem);
