/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import { Plugins, PushNotification, PushNotificationToken, PushNotificationActionPerformed } from '@capacitor/core';

import { rootState } from 'models/RootState';
import { solayAPI } from 'services/SolayAPI';
import { errorReporter } from './ErrorReporter';

const { PushNotifications } = Plugins;

export class NotificationService {
    public static requestPermissions() {
        PushNotifications.requestPermission().then((result) => {
            console.log('setting up PushNotifications');
            if (result.granted) {
                PushNotifications.register();
            } else {
                console.log('PushNotification Please enable push!', result);
            }
        });
    }

    public static setToken() {
        PushNotifications.addListener('registration', (token: PushNotificationToken) => {
            console.log('PushNotificationToken', token);
            solayAPI
                .userUpdate({ device_token: token.value })
                .then((response) => {
                    console.log('response', response);
                })
                .catch((error) => {
                    errorReporter.error('PushNotifications register solayAPI.userUpdate device_token', error);
                });
        });
    }

    public static getError() {
        PushNotifications.addListener('registrationError', (error: any) => {
            errorReporter.error('PushNotification registrationError', error);
        });
    }

    public static getNotifications() {
        PushNotifications.addListener('pushNotificationReceived', (notification: PushNotification) => {
            if (notification.body && notification.body.length > 0) {
                rootState.setToastMessage(notification);
                setTimeout(() => {
                    rootState.setIsToastOpen(true);
                }, 100);
            }
            console.log('PushNotification', notification);
        });
        PushNotifications.addListener(
            'pushNotificationActionPerformed',
            (notification: PushNotificationActionPerformed) => {
                console.log('PushNotification', notification);
            },
        );
    }

    public static setupPushNotifications() {
        this.requestPermissions();
        this.setToken();
        this.getError();
        this.getNotifications();
    }
}
