import * as React from 'react';
import { useState, useEffect } from 'react';
import { IonInput, IonIcon, IonButton, IonItem } from '@ionic/react';
import './InputSearch.css';

interface ContainerProps {
    onSearch: (arg0: string | undefined) => void;
}

const InputSearch: React.FC<ContainerProps> = (props) => {
    const { onSearch } = props;
    const [query, setQuery] = useState<string>('');

    useEffect(() => {
        if (query.length >= 3) {
            onSearch(query);
        }
    }, [query]);

    return (
        <IonItem className="inputs rounded-full overflow-hidden">
            <IonInput
                type="search"
                autocapitalize="on"
                placeholder="enter resort name"
                required={true}
                autofocus={true}
                inputmode="search"
                enterkeyhint="search"
                onIonChange={(e) => setQuery(e.detail.value!)}
                onKeyPress={(e) => e.key === 'Enter' && props.onSearch(query)}
            />
            <IonButton
                className="search-button rounded-full overflow-hidden"
                color="gold"
                fill="solid"
                strong={true}
                onClick={(e) => props.onSearch(query)}
            >
                <IonIcon color="light" name="search-outline" />
            </IonButton>
        </IonItem>
    );
};

export default InputSearch;
