import * as React from 'react';
import { PropsWithChildren } from 'react';
import { IonAvatar, IonItem } from '@ionic/react';
import './MessagesCard.css';
import { rootState } from 'models/RootState';
import moment from 'moment';
import { useHistory } from 'react-router';

const MessageCard: React.FC<PropsWithChildren<any>> = ({ ...props }) => {
    const history = useHistory();
    const timezone = Intl.DateTimeFormat().resolvedOptions().timeZone ?? 'America/New_York';
    return (
        <IonItem
            className="messageItem"
            onClick={(e) => {
                // @TODO
                rootState.setMessages(JSON.parse(JSON.stringify(props.conversation.messages)));
                e.preventDefault();
                history.push('/messages');
            }}
        >
            <div className="message-border">
                <div className="messagesContainer">
                    <div>
                        <IonAvatar>
                            <img src={props.conversation.getProfileImg()} alt="profile" />
                        </IonAvatar>
                    </div>
                    <div className="box-1">
                        <p className="name bold font-size-16"> {props.conversation.username}</p>
                    </div>
                    <div className="box-1">
                        <p className="ion-align-items-end date">
                            {moment.tz(props.conversation.getMostRecentMessageDate(), 'UTC').tz(timezone).fromNow()}
                        </p>
                    </div>
                </div>
                <div className="">
                    <p className="ion-text-left messageText font-size-18">
                        {props.conversation.getMostRecentMessage().body || 'No Message'}
                    </p>
                </div>
            </div>
        </IonItem>
    );
};

export default MessageCard;
