import * as React from 'react';
import { PropsWithChildren } from 'react';
import { IonText } from '@ionic/react';
import './Text.css';

const Text: React.FC<PropsWithChildren<any>> = (props) => {
    return (
        <IonText color="light" class={props.class}>
            <p>{props.children}</p>
        </IonText>
    );
};

export default Text;
