import { types } from 'mobx-state-tree';

import { isEmpty } from 'lodash';
import Resort from './Resort';
import ResortArea from './ResortArea';
import LoungeSeat from './LoungeSeat';
import CabanaSeat from './CabanaSeat';

const Order = types
    .model('Order', {
        id: types.identifierNumber,

        // user
        user_id: types.integer,
        email: types.maybe(types.string),
        rating: types.maybe(types.integer),

        // resort
        resort: types.maybeNull(Resort),
        beach_pool: types.maybeNull(ResortArea),
        beach_pool_id: types.maybeNull(types.integer),
        cabana_id: types.maybeNull(types.integer),
        cabana_ref_seat: types.maybeNull(types.string),
        lounge_id: types.maybeNull(types.integer),
        lounge_ref_seat: types.maybe(types.string),
        total_chairs: types.maybeNull(types.integer),
        total_cabanas: types.maybeNull(types.integer),
        cabana_unit_price: types.maybeNull(types.string),
        lounge_unit_price: types.maybeNull(types.string),

        // order
        total_price: types.maybeNull(types.string),
        reservation_date: types.string,
        note: types.maybeNull(types.string),
        status: types.string,
        rental_type: types.string,
        comment: types.maybeNull(types.string),

        // payment info
        transaction_id: types.maybeNull(types.string),
        tip_amount: types.maybe(types.string),

        lounge_seats: types.array(LoungeSeat),
        cabana_seats: types.array(CabanaSeat),

        // meta
        created_at: types.string,
        updated_at: types.maybe(types.string),
    })
    .views((self) => ({
        getSchedule() {
            switch (self.rental_type) {
                case 'fullday':
                    return 'All day';

                case 'afternoon':
                    return 'Afternoon';

                case 'morning':
                    return 'Morning';

                default:
                    return 'Unknown rental period';
            }
        },
        getDescription() {
            let description = '';
            if (self.total_chairs && self.total_chairs > 0) {
                description = self.total_chairs + (self.total_chairs > 1 ? ' lounge chairs' : ' lounge chair');
            }
            if (self.total_chairs && self.total_chairs > 0 && self.total_cabanas && self.total_cabanas > 0) {
                description += ', ';
            }
            if (self.total_cabanas && self.total_cabanas > 0) {
                if (!isEmpty(description)) {
                    description += ' ';
                }
                description += self.total_cabanas + (self.total_cabanas > 1 ? ' cabanas' : ' cabana');
            }
            return description;
        },
        isCancelable() {
            return ['reserved', 'ready_for_checkin'].includes(self.status);
        },
        getButtonActionByStatus() {
            switch (self.status) {
                case 'cancelled':
                    return 'Cancelled';

                case 'reserved':
                    // @TODO at this status we need the button to call solayAPI.userCodeValidate(code);
                    // @TODO shen to check if there are more API calls.
                    return 'Check In';

                // @TODO add copy if not day and time: "Check-in is not available until the day and time of your reservation."
                case 'ready_for_checkin':
                    return 'Complete Check In';

                case 'checked_in':
                    return 'Check Out';

                case 'checked_out':
                    // @TODO at this status we redirect them to view the Resort Area so they can make another reservation.
                    return 'Reserve Again';

                case 'completed':
                    return 'Completed';

                default:
                    return 'Unknown Status';
            }
        },
        getIconByStatus() {
            switch (self.status) {
                case 'cancelled':
                    return '/assets/icon/cancel.png';

                case 'reserved':
                    return '/assets/icon/in-progress.png';

                case 'ready_for_checkin':
                    return '/assets/icon/in-progress.png';

                case 'checked_in':
                    return '/assets/icon/complete.png';

                case 'checked_out':
                    return '/assets/icon/complete.png';

                case 'completed':
                    return '/assets/icon/complete.png';

                default:
                    return '/assets/icon/in-progress.png';
            }
        },
    }));

export default Order;
