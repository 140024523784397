import React from 'react';
import { IonLoading } from '@ionic/react';
import { Redirect } from 'react-router';
import { DISABLE_WHITE_LABEL } from 'AppConstants';
import { Brand } from 'services/Brand';
import { observer } from 'mobx-react';
import { rootState } from 'models/RootState';
import './Loading.css';
import { isEmpty } from 'lodash';

const Loading: React.FC = () => {
    const nonWhiteLabelSubdomains = ['app.solayapp.com', 'staging-app.solayapp.com', 'develop-app.solayapp.com'];
    if (!nonWhiteLabelSubdomains.includes(window.location.hostname)) {
        // on iOS you get ['localhost']
        const parts = window.location.hostname.split('.');
        // we are looking for ['hilton', 'solaypp', 'com']
        if (!DISABLE_WHITE_LABEL && parts.length > 2) {
            const subdomain = parts ? parts.shift() : null;
            if (subdomain) {
                Brand.setup(subdomain);

                // Brand properly setup
                if (isEmpty(rootState.brand) || rootState.brand?.resort_id < 0) {
                    return (
                        <IonLoading
                            cssClass="solay-loading"
                            isOpen={true}
                            showBackdrop={false}
                            message="Looking up resort"
                        />
                    );
                }
            }
        }
    }

    return <Redirect to="/welcome" />;
};

export default observer(Loading);
