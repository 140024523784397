import * as React from 'react';
import { Dispatch, SetStateAction, useState } from 'react';
import { IonAlert, IonButton, IonCol, IonGrid, IonInput, IonItem, IonItemDivider, IonRow } from '@ionic/react';
import { observer } from 'mobx-react';

import './ChairsCabanasPicker.css';

interface Props {
    title: string;
    resortName: string;
    type: string;
    waivesFees: boolean;
    disabled: boolean;
    units: number;
    setUnits: Dispatch<SetStateAction<number>>;
    unitPrice: number;
    maxUnitsThreshold: number;
    defaultUnits: number;
}

const ChairsCabanasPicker: React.FC<Props> = ({
    title,
    resortName,
    type,
    waivesFees,
    disabled,
    units,
    setUnits,
    unitPrice,
    maxUnitsThreshold,
}) => {
    const [showWaiveAlert, setWaiveAlert] = useState<boolean>(false);
    const [isWaiveAlertAccepted, setIsWaiveAlertAccepted] = useState<boolean>(false);
    const [showMaxUnitsAlert, setMaxUnitsAlert] = useState<boolean>(false);

    const price = !isNaN(unitPrice) ? unitPrice * units : 0 * units;

    const modifyInputNumber = (up: boolean) => {
        if (waivesFees) {
            setWaiveAlert(true);
        }

        if (up) {
            if (units >= maxUnitsThreshold) {
                setMaxUnitsAlert(true);
                return;
            }
            setUnits(units + 1);
        } else {
            if (units > 0) {
                setUnits(units - 1);
            }
        }
    };

    return (
        <>
            <IonAlert
                isOpen={showWaiveAlert === true && isWaiveAlertAccepted === false}
                onDidDismiss={() => {
                    setWaiveAlert(false);
                    setIsWaiveAlertAccepted(true);
                }}
                buttons={['OK']}
                message={resortName + ' is currently waiving ' + type + ' reservation fees'}
            />

            <IonAlert
                isOpen={showMaxUnitsAlert === true}
                onDidDismiss={() => setMaxUnitsAlert(false)}
                buttons={['OK']}
                message={'Guests can reserve only ' + maxUnitsThreshold + ' ' + type + ' per room reservation'}
            />

            <IonGrid className="mt-4">
                <IonRow>
                    <IonCol className="flex-grow-0 justify-start text-left whitespace-no-wrap">{title}</IonCol>
                    <IonCol className="flex-grow">
                        <IonItemDivider />
                    </IonCol>
                </IonRow>

                <IonRow className="qty-picker overflow-hidden rounded-full justify-between">
                    <IonCol size="4" class="chair-cabana-price">
                        ${price}
                    </IonCol>

                    <IonCol size="6">
                        <IonItem lines="none">
                            <IonButton
                                onClick={() => modifyInputNumber(false)}
                                className="qty-picker-input-minus-button rounded-full"
                                disabled={disabled}
                            >
                                -
                            </IonButton>
                        </IonItem>

                        <IonItem lines="none" className="flex-shrink-0">
                            <span className="qty-picker-input">{units}</span>
                            <IonInput value={units} placeholder="" className="sr-only" />
                        </IonItem>

                        <IonItem lines="none">
                            <IonButton
                                onClick={() => modifyInputNumber(true)}
                                className="qty-picker-input-plus-button rounded-full"
                                disabled={disabled}
                            >
                                +
                            </IonButton>
                        </IonItem>
                    </IonCol>
                </IonRow>
            </IonGrid>
        </>
    );
};

export default observer(ChairsCabanasPicker);
