import * as React from 'react';
import { useState } from 'react';
import { IonAlert, IonContent, IonLoading, useIonViewDidEnter, useIonViewWillLeave } from '@ionic/react';
import BackArrow from 'components/backArrow/BackArrow';
import './Conversations.css';
import MessageCard from 'components/messagesCard/MessagesCard';
import { solayAPI } from 'services/SolayAPI';
import { isNil, isEmpty } from 'lodash';
import { rootState } from 'models/RootState';
import { observer } from 'mobx-react';
import { useHistory } from 'react-router';
import Layout from 'components/Layout';

const Conversations: React.FC = () => {
    const [msg, setMsg] = useState<string>('');
    const [isLoading, setIsLoading] = useState(true);
    const history = useHistory();

    useIonViewDidEnter(() => {
        if (rootState.user?.isSuperAdmin()) {
            getAdminConversations();
        } else {
            setIsLoading(false);
            history.push('/search');
        }
    });

    useIonViewWillLeave(() => {
        setIsLoading(false);
    });

    const getAdminConversations = () => {
        setIsLoading(true);
        solayAPI.getAllMessages().then(handleGetConversationsSuccess, handleGetConversationsError);
    };

    const handleGetConversationsSuccess = (response: any) => {
        if (response.data?.code === solayAPI.successCode) {
            rootState.setAdminConversations(response.data.data);
        } else {
            // @TODO error handling
        }
        setIsLoading(false);
    };

    const handleGetConversationsError = (error: any) => {
        const msg = error.response?.data?.message ?? error.message ?? 'Unknown error.';
        setIsLoading(false);
        setMsg('The messages could not be loaded: ' + msg);
    };

    return (
        <Layout id="message-page">
            <IonContent>
                <BackArrow pageTitle="Messages" />
                <IonLoading isOpen={isLoading} mode="ios" message={'Loading messages...'} />
                <IonAlert isOpen={!isEmpty(msg)} onDidDismiss={() => setMsg('')} buttons={['OK']} message={msg} />
                {!isNil(rootState.adminConversations) && !isEmpty(rootState.adminConversations) ? (
                    <div className="reservations-container">
                        {rootState.getAdminConversationsNewestFirst().map((conversation, index) => {
                            return <MessageCard key={index} conversation={conversation}></MessageCard>;
                        })}
                    </div>
                ) : (
                    <div>
                        <p className="no-message-found">No Messages found</p>
                    </div>
                )}
            </IonContent>
        </Layout>
    );
};

export default observer(Conversations);
