import * as React from 'react';
import { useState } from 'react';
import { IonAlert, IonImg, IonItem, IonLabel, IonList, IonLoading } from '@ionic/react';
import { observer } from 'mobx-react';
import { isEmpty, isNumber, isUndefined } from 'lodash';

import { LoginResponse } from 'models/LoginResponse';
import { rootState } from 'models/RootState';
import { solayAPI } from 'services/SolayAPI';
import Description from 'components/description/Description';
import Gallery from 'components/gallery/Gallery';

interface Props {
    isResort?: boolean;
    isReservation?: boolean;
}

const OrderActions: React.FC<Props> = (props) => {
    const { isReservation } = props;
    const [message, setMessage] = useState<string>('');
    const [loadingMessage, setLoadingMessage] = useState<string>('');
    const [showCancelAlert, setShowCancelAlert] = useState(false);

    let resortPhotos: any[] = [];
    if (rootState.currentResortArea?.attachments) {
        rootState.currentResortArea?.attachments.forEach((attachment: any) => {
            resortPhotos = resortPhotos.concat([attachment.path]);
        });
    }

    /**
     * @TODO determine what actions possible based on the status of the order
     * Status ENUM from the API DB: 'reserved','ready_for_checkin','checked_in','checked_out','completed','cancelled'
     * Status in Laravel updateStatus: reserved,ready_for_checkin,checked_in,checked_out,completed,cancelled (matches! :)
     * Which of these are actually in use?
     * - cancelled
     * -
     */

    const cancelReservation = () => {
        if (rootState.currentReservation && isNumber(rootState.currentReservation.id)) {
            setLoadingMessage('Canceling reservation');
            solayAPI.orderCancel(rootState.currentReservation.id).then(handleCancelSuccess, handleCancelError);
        } else {
            alert('Error no valid reservation id available.');
        }
    };

    const handleCancelSuccess = (response: any) => {
        if (response.data?.code === solayAPI.successCode) {
            // @TODO add messaging around fees?
            solayAPI.userView().then(handleUpdateUserSuccess, handleUpdateUserError);
        } else {
            setLoadingMessage('');
            setMessage('Error canceling reservation: ' + response.data.message);
        }
    };

    const handleCancelError = (error: any) => {
        setLoadingMessage('');
        const msg = error.response?.data?.message ?? error.message ?? 'Unknown error.';
        setMessage(msg);
    };

    const handleUpdateUserSuccess = (response: LoginResponse) => {
        setLoadingMessage('');
        if (response.status === 200) {
            rootState.setUserOrders(response.data.data.orders);
            if (rootState.currentReservation) {
                // @TODO we want the button to update so we need it to be observable.
                rootState.setReservationById(rootState.currentReservation.id);
            }
            setMessage('Your reservation has been canceled');
        } else {
            setMessage(response.data.message ?? 'Unable to load your reservations.');
        }
    };
    const handleUpdateUserError = (error: any) => {
        setLoadingMessage('');
        setMessage(error?.message ?? 'Unkown order error.');
    };

    return (
        <IonList className="list-action">
            <IonLoading isOpen={!isEmpty(loadingMessage)} message={loadingMessage} />
            <IonAlert
                isOpen={!isEmpty(message)}
                onDidDismiss={() => setMessage('')}
                message={message}
                buttons={['OK']}
                mode="ios"
            />
            <IonAlert
                isOpen={showCancelAlert}
                onDidDismiss={() => setShowCancelAlert(false)}
                message="Are you sure you want to cancel your reservation?"
                buttons={[
                    {
                        text: 'No',
                        role: 'cancel',
                        cssClass: 'secondary',
                    },
                    {
                        text: 'Yes',
                        handler: cancelReservation,
                    },
                ]}
            />
            {isReservation &&
                !isUndefined(rootState.currentReservation) &&
                rootState.currentReservation?.isCancelable() && (
                    <IonItem lines="none" onClick={() => setShowCancelAlert(true)}>
                        <IonLabel>cancel reservation</IonLabel>
                        <IonImg slot="end" src="/assets/icon/icon-close.svg" />
                    </IonItem>
                )}

            <Description descriptionText={rootState.currentResortArea?.description} />
            <Gallery imageUrl={resortPhotos} />
            <IonItem lines="none" detail={false} routerLink="/support">
                <IonLabel>support</IonLabel>
                <IonImg slot="end" src="/assets/icon/icon-happy.svg" />
            </IonItem>
        </IonList>
    );
};

export default observer(OrderActions);
