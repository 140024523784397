import * as React from 'react';
import { useState } from 'react';
import { IonButton, IonItem, IonInput, useIonViewDidEnter, IonLoading, IonAlert } from '@ionic/react';

import './MessageInput.css';

import { solayAPI } from 'services/SolayAPI';
import { isEmpty, isNil } from 'lodash';
// import { rootState } from 'models/RootState';
import { observer } from 'mobx-react';
import { SendMessageRequest } from 'models/SendMessageRequest';
import { rootState } from 'models/RootState';
import { useForm, Controller } from 'react-hook-form';
import { useHistory } from 'react-router';

interface Props {
    getMessages: () => void;
}

const MessageInput: React.FC<Props> = (props) => {
    const history = useHistory();
    const [msg, setMsg] = useState<string | undefined>();
    const [showLoading, setShowLoading] = useState<boolean>(false);

    const [successMsg, setSuccessMsg] = useState<string>('');
    const { register, handleSubmit, control } = useForm();

    useIonViewDidEnter(() => {
        setMsg('');
    });

    const handleSendMessageError = (error: any) => {
        setShowLoading(false);
        const msg = error.response?.data?.message ?? error.message ?? 'Unknown error.';
        setSuccessMsg('The message could not be sent: ' + msg);
    };

    const sendMessage = () => {
        if (!isNil(msg) && !isEmpty(msg)) {
            setShowLoading(true);

            // if admin, find user_id and set to
            const sendMessageRequestData: SendMessageRequest = {
                message: msg,
            };

            // If we are a super admin then set the user we want to send the message to
            if (rootState.user?.isSuperAdmin()) {
                sendMessageRequestData.user_id = rootState.userMessages[0].getUserId();
            }

            solayAPI.sendMessage(sendMessageRequestData).then((data) => {
                setShowLoading(false);
                setMsg('');

                if (data?.data?.message) {
                    if (data.data.code === solayAPI.successCode) {
                        setSuccessMsg('Thanks for your message.');
                        if (rootState.user?.isSuperAdmin()) {
                            history.push('/conversations');
                        } else {
                            props.getMessages();
                        }
                    }
                }
            }, handleSendMessageError);
        } else {
            setSuccessMsg('Please enter a message');
        }
    };

    return (
        <form onSubmit={handleSubmit(sendMessage)} className="chat-footer mb-safe" slot="fixed">
            <IonLoading isOpen={showLoading === true} message={'Sending message...'} />
            <IonAlert
                isOpen={!isEmpty(successMsg)}
                onDidDismiss={() => setSuccessMsg('')}
                buttons={['OK']}
                message={successMsg}
            />
            <Controller
                render={() => (
                    <IonItem id="messageItem" lines="none">
                        <IonInput
                            name="message"
                            value={msg}
                            enterkeyhint="send"
                            className="sendMessageInput"
                            placeholder="type message"
                            onIonChange={(e) => setMsg(e.detail.value!)}
                        ></IonInput>
                        {/* This hidden submit needs to be here to get the form submission when you hit Enter  */}
                        <input type="submit" className="hidden" />
                        <IonButton fill="clear" disabled={isEmpty(msg)} onClick={() => sendMessage()}>
                            <img className="iconMessage" src="/assets/icon/message-icon.png" alt="icon" />
                        </IonButton>
                    </IonItem>
                )}
                defaultValue=""
                control={control}
                name="message"
            />
        </form>
    );
};

export default observer(MessageInput);
