import { isNil } from 'lodash';
import { types } from 'mobx-state-tree';

import Attachement from './Attachement';
import Cabana from './Cabana';
import Lounge from './Lounge';
import ResortAreaItem from './ResortAreaItem';

const ResortArea = types
    .model('ResortArea', {
        id: types.identifierNumber,
        resort_id: types.maybeNull(types.integer),
        name: types.maybeNull(types.string),
        description: types.maybeNull(types.string),
        vicinity_map: types.maybeNull(types.string),
        layout: types.maybeNull(types.string),
        display_image: types.maybeNull(types.string),
        area_description: types.maybeNull(types.string),
        cabanas: types.array(Cabana),
        lounges: types.array(Lounge),
        attachments: types.array(Attachement),
        lounge_reservation: types.maybeNull(types.integer),
        cabana_reservation: types.maybeNull(types.integer),
        latitude: types.maybeNull(types.string),
        longitude: types.maybeNull(types.string),
        scale: types.maybeNull(types.number),
        items: types.maybeNull(types.array(ResortAreaItem)),
    })
    .views((self) => ({
        getVicinityMap(): string {
            if (!isNil(self.layout)) {
                return self.layout;
            }

            // @TODO provide coming soon or missing resort map graphic.
            return '/assets/mocks/resort-map.png';
        },
    }));

export default ResortArea;
