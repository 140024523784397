import * as React from 'react';
import { useState, useEffect } from 'react';
import { IonItem, IonSelect, IonSelectOption } from '@ionic/react';
import { observer } from 'mobx-react';

import { rootState } from 'models/RootState';
import './PoolSelector.css';

const PoolSelector: React.FC = () => {
    const defaultCurrentAreaId = rootState.currentResortArea?.id;
    const [currentAreaId, setCurrentAreaId] = useState(defaultCurrentAreaId);

    const resortAreas = rootState.currentResort?.getResortAreas();

    useEffect(() => {
        if (resortAreas && resortAreas.length > 0) {
            const newResortArea: any = resortAreas.find(({ id }) => id === currentAreaId);
            rootState.setResortArea(newResortArea);
        }
    }, [resortAreas, currentAreaId]);

    return (
        <IonItem lines="none">
            <IonSelect
                value={currentAreaId}
                onIonChange={(event: CustomEvent) => setCurrentAreaId(event.detail.value)}
                interface="popover"
                interfaceOptions={{
                    cssClass: 'pool-selector',
                }}
                className="rounded-full"
            >
                {resortAreas && resortAreas.length > 0 ? (
                    resortAreas.map(({ id, name }) => {
                        return (
                            <IonSelectOption key={id} value={id}>
                                {name}
                            </IonSelectOption>
                        );
                    })
                ) : (
                    <IonSelectOption disabled>No locations available</IonSelectOption>
                )}
            </IonSelect>
        </IonItem>
    );
};

export default observer(PoolSelector);
