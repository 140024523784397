import * as React from 'react';
import { useState, useEffect, useMemo } from 'react';
import {
    IonAlert,
    IonButton,
    IonGrid,
    IonRow,
    IonCol,
    IonItemDivider,
    useIonViewDidEnter,
    IonLoading,
} from '@ionic/react';
import { Capacitor, Plugins } from '@capacitor/core';
import { useHistory } from 'react-router-dom';
import { observer } from 'mobx-react';
import moment from 'moment';
import haversine from 'haversine-distance';
import { isEmpty, isNil, isUndefined } from 'lodash';
import { Elements } from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';
import { MaterialUiPickersDate } from '@material-ui/pickers/typings/date';
import { STRIPE_PK } from 'AppConstants';

import { OrderUpdateRequest } from 'models/OrderUpdateRequest';
import { rootState } from 'models/RootState';
import { solayAPI } from 'services/SolayAPI';
import PrimaryButton from 'components/buttons/PrimaryButton';
import ChairsCabanasPicker from 'components/chairsCabanasPicker/ChairsCabanasPicker';
import InputDatePicker from 'components/forms/input-datepicker/InputDatePicker';
import OrderConfirmationModal from 'components/order/confirmOrderModal/OrderConfirmationModal';
import PaymentFormModal from 'components/PaymentFormModal';
import { getReservationTime } from 'utilities/getReservationTime';
import './OrderForm.css';
import { NotificationService } from '../../services/NotificationService';

// Make sure to call `loadStripe` outside of a component’s render to avoid
// recreating the `Stripe` object on every render.
const stripePromise = loadStripe(STRIPE_PK);

interface Props {
    isResort?: boolean;
    isReservation?: boolean;
}

const OrderForm: React.FC<Props> = (props) => {
    const { Geolocation } = Plugins;
    const { isResort, isReservation } = props;
    const history = useHistory();

    // ---------- CONSTANTS ----------
    const reservationOptions = rootState.brand.getTimeSlots();

    // @TODO discover what the max acceptable distance from the center of the
    // resort area is and reduce this to that value
    const MAX_ACCEPTABLE_DISTANCE_IN_METERS = 100000;
    const HOUR_OF_DAY = useMemo(() => moment().hour(), []);

    // ---------- CODE VALIDATION STATE ----------
    const defaultIsValidCode = rootState.user?.verifiedTill()
        ? moment().format('YYYY-MM-DD') <= moment(rootState.user?.verifiedTill()).format('YYYY-MM-DD')
        : false;
    const [isValidCode, setIsValidCode] = useState<boolean>(defaultIsValidCode);

    // ---------- ALERT MESSAGE STATE ----------
    const [message, setMessage] = useState<string>('');

    // ---------- TIME STATE ----------
    const defaultReservationTime: string = getReservationTime(rootState.currentReservation?.rental_type);
    const [reservationTime, setReservationTime] = useState<string>(defaultReservationTime);

    // ---------- DATE STATE ----------
    // @TODO replace moment w/ something else
    // dayjs is a good candidate since it uses the same API: https://day.js.org/
    const currentReservationDate = moment(rootState.currentReservation?.reservation_date, 'YYYY-MM-DD');
    const defaultSelectedDate =
        isReservation && !isNil(rootState.currentReservation)
            ? moment(rootState.currentReservation.reservation_date, 'YYYY-MM-DD')
            : null;
    const defaultSelectedDay = defaultSelectedDate?.format('YYYY-MM-DD');
    const [selectedDate, setSelectedDate] = useState<MaterialUiPickersDate>(defaultSelectedDate);
    const currentSelectedDay = selectedDate?.format('YYYY-MM-DD');
    const isValidDay =
        isReservation && defaultSelectedDate
            ? defaultSelectedDate.format('YYYY-MM-DD') === moment().format('YYYY-MM-DD')
            : true;
    const isPastDay = isReservation ? moment().isAfter(currentReservationDate, 'day') : false;
    const isToday = useMemo(() => moment(0, 'HH').diff(selectedDate, 'days') == 0, [selectedDate]);

    // ---------- LOUNGE CHAIR STATE ----------
    const defaultLoungeQty =
        isReservation && rootState.currentReservation?.total_chairs ? rootState.currentReservation.total_chairs : 0;
    const [loungeQty, setLoungeQty] = useState<number>(defaultLoungeQty);
    const [maxLoungeQty, setMaxLoungeQty] = useState<number>(4); //eslint-disable-line
    const [loungePrice, setLoungePrice] = useState<number>(0);

    // ---------- CABANA STATE ----------
    const defaultCabanaQty =
        isReservation && rootState.currentReservation?.total_cabanas ? rootState.currentReservation.total_cabanas : 0;
    const [cabanaQty, setCabanaQty] = useState<number>(defaultCabanaQty);
    const [maxCabanaQty, setMaxCabanaQty] = useState<number>(1); //eslint-disable-line
    const [cabanaPrice, setCabanaPrice] = useState<number>(20);

    // ---------- GRAND TOTAL STATE ----------
    const [grandTotal, setGrandTotal] = useState<number>(0.0);

    // ---------- SHOW/HIDE CONTENT STATE ----------
    const [showValidateCodeAlert, setShowValidateCodeAlert] = useState<boolean>(false);
    const [showEditReservationAlert, setShowEditReservationAlert] = useState<boolean>(false);
    const [showOrderConfirmationModal, setShowOrderConfirmationModal] = useState(false);
    const [showPaymentModal, setShowPaymentModal] = useState(false);
    const [showCheckOutAlert, setShowCheckOutAlert] = useState<boolean>(false);

    // ---------- OVERALL FORM STATE ----------
    const [isLoading, setIsLoading] = useState(false);
    const [isFormDirty, setIsFormDirty] = useState<boolean>(false);
    const isResortAdmin = rootState.user?.isResortAdmin() ? true : false;
    const isCanceledReservation = !isUndefined(isReservation) && rootState.currentReservation?.status === 'cancelled';
    const isFormDisabled = isResortAdmin || isPastDay || isCanceledReservation;
    const [hasPendingOrderCreateServerRequest, setHasPendingOrderCreateServerRequest] = useState<boolean>(false);

    // ---------- SIDE EFFECTS ----------
    useEffect(() => {
        if (isResort && !isNil(rootState.currentResort)) {
            setLoungePrice(rootState.currentResort.getPrice('lounge', 'all day', selectedDate));
            setCabanaPrice(rootState.currentResort.getPrice('cabana', 'all day', selectedDate));
        }
    }, [selectedDate]);

    // Allow for revising reservation when any reservation details are changed
    useEffect(() => {
        if (isReservation && !isNil(rootState.currentReservation)) {
            if (currentSelectedDay !== defaultSelectedDay) {
                setIsFormDirty(true);
                // console.log('mismatched reservation date: ', currentSelectedDay, ' !== ', defaultSelectedDay);
            } else if (getReservationTime(reservationTime) !== defaultReservationTime) {
                setIsFormDirty(true);
                // console.log(
                //     'mismatched reservation time: ',
                //     getReservationTime(reservationTime),
                //     ' !== ',
                //     defaultReservationTime,
                // );
            } else if (loungeQty !== defaultLoungeQty) {
                setIsFormDirty(true);
                // console.log('mismatched chairs: ', loungeQty, ' !== ', defaultLoungeQty);
            } else if (cabanaQty !== defaultCabanaQty) {
                setIsFormDirty(true);
                // console.log('mismatched cananas: ', cabanaQty, ' !== ', defaultCabanaQty);
            } else {
                setIsFormDirty(false);
                // console.log('no mismatch');
            }
        }
    }, [isFormDirty, currentSelectedDay, reservationTime, loungeQty, cabanaQty]);

    // @TODO replace this with a prompt asking if they want to update the
    // reservation or not. If not, reset the state of selected day, reservation
    // time, lounge qty, and cabana qty to the values in the original
    // reservation.
    useEffect(() => {
        if (isFormDirty) {
            setShowEditReservationAlert(true);
        }
    }, [isFormDirty]);

    // Fix state of current reservation details
    useIonViewDidEnter(() => {
        // console.log('restore default reservation on enter');
        restoreDefaultReservation();
    }, [defaultReservationTime, defaultSelectedDate, defaultLoungeQty, defaultCabanaQty, defaultCabanaQty]);

    // ---------- EVENT HANDLERS ----------
    // @TODO reset the Cabana Lounge form or update the price, whichever is easier.
    // @param option
    const reservationTimeChange = (option: string) => {
        if (isResort && !isNil(rootState.currentResort)) {
            setReservationTime(option);
            setLoungePrice(rootState.currentResort?.getPrice('lounge', option, selectedDate));
            setCabanaPrice(rootState.currentResort?.getPrice('cabana', option, selectedDate));
            calculateGrandTotal();
        } else if (isReservation) {
            setReservationTime(option);
        }
    };

    const completeReservation = (): void => {
        if (cabanaQty === 0 && loungeQty === 0) {
            setMessage('Please select number of seats.');
            return;
        }

        if (isResort && !selectedDate) {
            setMessage('Please select a date.');
            return;
        }

        if (reservationTime && !getIsReservationTimeAvailable(reservationTime)) {
            setMessage(
                'The reservation time of ' + reservationTime + ' is not available. Please change the reservation time.',
            );
            return;
        }

        if (isResort) {
            setShowOrderConfirmationModal(true);
            return;
        }

        if (isReservation && isPastDay) {
            const resortId = rootState.currentReservation?.resort?.id;
            history.push('/resort/' + resortId);
            return;
        }

        if (
            isReservation &&
            isFormDirty &&
            !isNil(rootState.currentReservation) &&
            !isNil(rootState.currentResortArea) &&
            !isNil(currentSelectedDay)
        ) {
            const order: OrderUpdateRequest = {
                id: rootState.currentReservation.id,
                beach_pool_id: rootState.currentResortArea.id,
                check_seat: 0,
                rental_type: reservationTime,
                reservation_date: currentSelectedDay,
                total_cabanas: cabanaQty,
                total_chairs: loungeQty,
            };
            // @TODO show loading
            // @TODO error handling
            solayAPI.orderUpdate(order);
            setMessage('Your reservation has been updated.');
            history.push('/search');
            return;
        }

        if (isReservation && !isValidCode) {
            setShowValidateCodeAlert(true);
            return;
        }

        if (isReservation && isValidCode && !isValidDay) {
            setMessage('To check in, it must be the day of your reservation.');
            return;
        }

        if (
            isReservation &&
            isValidCode &&
            isValidDay &&
            rootState.currentReservation?.beach_pool?.latitude &&
            rootState.currentReservation?.beach_pool?.longitude &&
            rootState.currentReservation?.status !== 'checked_in'
        ) {
            const beachPoolLatLong = {
                latitude: parseFloat(rootState.currentReservation.beach_pool.latitude),
                longitude: parseFloat(rootState.currentReservation.beach_pool.longitude),
            };
            Geolocation.getCurrentPosition()
                .then((data: any) => {
                    const userLatLong = { latitude: data.coords.latitude, longitude: data.coords.longitude };
                    const distance = Math.round(haversine(userLatLong, beachPoolLatLong));
                    // console.log('beachPoolLatLong: ', beachPoolLatLong);
                    // console.log('userlLatLong: ', userLatLong);
                    // console.log('distance: ', distance);

                    if (rootState.currentReservation?.id && distance < MAX_ACCEPTABLE_DISTANCE_IN_METERS) {
                        // @TODO show loading
                        // @TODO error handling
                        solayAPI.updateOrderStatus(rootState.currentReservation.id, 'checked_in');

                        const allItems: number[] = [];

                        if (
                            rootState.currentReservation?.lounge_seats &&
                            rootState.currentReservation?.lounge_seats.length > 0
                        ) {
                            rootState.currentReservation?.lounge_seats.forEach((item: any) => {
                                allItems.push(item.ref_seat);
                            });
                        }

                        if (
                            rootState.currentReservation?.cabana_seats &&
                            rootState.currentReservation?.cabana_seats.length > 0
                        ) {
                            rootState.currentReservation?.cabana_seats.forEach((item: any) => {
                                allItems.push(item.ref_seat);
                            });
                        }

                        setTimeout(() => {
                            rootState.setReservedItems(allItems);
                            setMessage('Your reservation was successfully checked in. Your seats are here.');
                        }, 100);
                    } else {
                        setMessage('To check in, you must be at the pool or beach where you reserved seats.');
                        return;
                    }
                })
                .catch(() => {
                    setMessage('You must turn on location permissions to check in.');
                    return;
                });
        }

        if (isReservation && isValidDay && rootState.currentReservation?.status === 'checked_in') {
            setShowCheckOutAlert(true);
            return;
        }
    };

    const calculateGrandTotal = (): void => {
        // @TODO dynamic pricing based on dates
        setGrandTotal(loungeQty * loungePrice + cabanaQty * cabanaPrice);
    };

    const confirmOrder = (): void => {
        if (isEmpty(rootState.stripeToken)) {
            setShowPaymentModal(true);
            return;
        }

        if (
            rootState.stripeToken &&
            selectedDate &&
            rootState.currentResortArea &&
            !hasPendingOrderCreateServerRequest
        ) {
            setHasPendingOrderCreateServerRequest(true);
            setIsLoading(true);
            solayAPI
                .orderCreate({
                    beach_pool_id: rootState.currentResortArea.id,
                    check_seat: 0,
                    rental_type: reservationTime,
                    reservation_date: selectedDate?.format('YYYY-MM-DD'),
                    total_cabanas: cabanaQty,
                    total_chairs: loungeQty,
                    // @TODO we cannot rely on the accuracy/security of this number:
                    total_price: grandTotal,
                    stripe_token: rootState.stripeToken,
                })
                .then((results) => {
                    setHasPendingOrderCreateServerRequest(false);
                    setIsLoading(false);
                    if (results.data.code !== solayAPI.successCode) {
                        rootState.setStripeToken('');
                        setMessage(
                            results.data.message ?? 'Unable to create reservation. Unknown error. Please try again.',
                        );
                        // Close the modals.
                        setShowPaymentModal(false);
                        setShowOrderConfirmationModal(false);
                        return;
                    }
                    // Close the modals.
                    setShowPaymentModal(false);
                    setShowOrderConfirmationModal(false);

                    setMessage(
                        results.data.message ?? 'Error. Success message missing. Please check the reservations screen.',
                    );

                    if (Capacitor.isPluginAvailable('PushNotifications')) {
                        NotificationService.setupPushNotifications();
                        console.log('Push is available');
                    } else {
                        console.warn('Push not available');
                    }

                    history.push('/search');
                    return;
                })
                .catch((error) => {
                    setIsLoading(false);
                    setHasPendingOrderCreateServerRequest(false);
                    rootState.setStripeToken('');
                    const msg =
                        error?.response?.data?.message ?? error.message ?? 'Unknown server error. Please try again.';
                    setMessage('Error: ' + msg);
                    return;
                });
        }
    };

    const onValidateHandler = (data: any) => {
        setIsLoading(true);
        solayAPI
            .userCodeValidate(data.validationCode)
            .then((results) => {
                setIsLoading(false);
                setShowValidateCodeAlert(false);
                if (results.data.code === solayAPI.successCode) {
                    setIsValidCode(true);
                    rootState.setValidTill(results.data.data.verified_till.split(' ')[0]);
                    if (isValidDay) {
                        completeReservation();
                    } else {
                        setMessage(results.data.message ?? 'Unknown error please check your reservation date.');
                    }
                    return;
                } else {
                    setMessage(results.data.message ?? 'Unknown error validating code.');
                    return;
                }
            })
            .catch((error) => {
                setIsLoading(false);
                setShowValidateCodeAlert(false);
                const msg =
                    error.response.data.message ??
                    error.message ??
                    'Unknown server error. Please try validating your code again.';
                setMessage('Error: ' + msg);
                return;
            });
    };

    const onCheckOutHandler = () => {
        if (rootState.currentReservation?.id) {
            // @TODO show loading
            // @TODO error handling
            solayAPI.updateOrderStatus(rootState.currentReservation.id, 'checked_out');
            setMessage('Your reservation was successfully checked out.');
            history.push('/search');
        }
    };

    const restoreDefaultReservation = () => {
        setReservationTime(defaultReservationTime);
        setSelectedDate(defaultSelectedDate);
        setLoungeQty(defaultLoungeQty);
        setCabanaQty(defaultCabanaQty);
        setIsFormDirty(false);
        setShowEditReservationAlert(false);
    };

    // ---------- UTILITIES ----------
    // @TODO get this reactive based on update in the rootState.currentReservation (try canceling a reservation and this button should update but it doesn't). See .extends docs: https://mobx-state-tree.js.org/concepts/volatiles
    const getButtonText = () => {
        if (isResort) {
            return 'Reserve Now';
        } else if (isReservation && isPastDay) {
            return 'Reserve Again';
        } else if (isReservation && isFormDirty && rootState.currentReservation?.status !== 'cancelled') {
            return 'Update Reservation';
        } else if (
            isReservation &&
            isValidCode &&
            isValidDay &&
            rootState.currentReservation?.status !== 'checked_in'
        ) {
            return 'Complete Check In';
        } else if (isReservation && !isNil(rootState.currentReservation)) {
            return rootState.currentReservation.getButtonActionByStatus();
        } else {
            return 'Unknown Status';
        }
    };

    const getIsReservationTimeAvailable = (option: string) => {
        if (isFormDisabled) return false;

        const isToday = moment(0, 'HH').diff(selectedDate, 'days') == 0;

        if (!isToday) return true;

        const HOUR_OF_DAY = moment().hour();

        switch (option) {
            case 'all day':
                return HOUR_OF_DAY < 12;
            case 'morning':
                return HOUR_OF_DAY < 8;
            case 'afternoon':
                return HOUR_OF_DAY < 12;
            default:
                return true;
        }
    };

    const getIsSubmitEnabled = () => {
        // Don't allow submissions for resort admins or cancelled reservations
        if (isResortAdmin || isCanceledReservation) return false;

        // Don't allow submissions when no reservation times are available for the day
        if (isToday) return HOUR_OF_DAY < 12 ? true : false;

        // Allow submissions in all other circumstances
        return true;
    };

    return (
        <div id="reservation-form">
            <IonLoading isOpen={isLoading} message="Please wait..." />

            <IonAlert
                isOpen={!isEmpty(message)}
                onDidDismiss={() => setMessage('')}
                message={message}
                cssClass="alert--rounded"
            />

            <IonAlert
                isOpen={showValidateCodeAlert}
                header="ENTER RESORT CODE"
                message="Check your text messages for resort code from Solay. Enter it here:"
                cssClass="alert--large"
                inputs={[
                    {
                        name: 'validationCode',
                        type: 'text',
                        placeholder: 'Validation code',
                    },
                ]}
                buttons={[
                    {
                        text: 'Cancel',
                        role: 'cancel',
                        handler: () => {
                            setShowValidateCodeAlert(false);
                        },
                    },
                    {
                        text: 'Ok',
                        handler: onValidateHandler,
                    },
                ]}
            />

            <IonAlert
                isOpen={showEditReservationAlert}
                header="EDIT RESERVATION"
                message="Are you sure you want to modify your existing reservation?"
                buttons={[
                    {
                        text: 'No',
                        role: 'cancel',
                        handler: restoreDefaultReservation,
                    },
                    {
                        text: 'Yes',
                        handler: () => {
                            setShowEditReservationAlert(false);
                        },
                    },
                ]}
            />

            <OrderConfirmationModal
                onOrderConfirmed={confirmOrder}
                showModal={showOrderConfirmationModal}
                setShowModal={setShowOrderConfirmationModal}
            />

            <Elements stripe={stripePromise}>
                <PaymentFormModal
                    showModal={showPaymentModal}
                    setShowModal={setShowPaymentModal}
                    onHasStripeToken={confirmOrder}
                />
            </Elements>

            <IonAlert
                isOpen={showCheckOutAlert}
                header="CHECK OUT"
                message="Are you sure you want to check out?"
                buttons={[
                    {
                        text: 'No',
                        role: 'cancel',
                        handler: () => {
                            setShowCheckOutAlert(false);
                        },
                    },
                    {
                        text: 'Yes',
                        handler: onCheckOutHandler,
                    },
                ]}
            />

            <IonGrid>
                <IonRow>
                    <IonCol className="flex-grow-0 justify-start text-left whitespace-no-wrap">date & time</IonCol>
                    <IonCol className="flex-grow">
                        <IonItemDivider />
                    </IonCol>
                </IonRow>
            </IonGrid>

            <InputDatePicker value={selectedDate} onChange={setSelectedDate} disabled={isFormDisabled} />

            <div className="button-group">
                {reservationOptions &&
                    reservationOptions.map((option: string) => {
                        return (
                            <IonButton
                                key={option}
                                onClick={() => reservationTimeChange(option)}
                                disabled={!getIsReservationTimeAvailable(option)}
                                className="rounded-full text-sm text-black flex-grow"
                                color={getReservationTime(reservationTime) === option ? 'gold' : 'grey'}
                                fill="solid"
                                size="small"
                            >
                                {option}
                            </IonButton>
                        );
                    })}
            </div>

            {rootState.currentResortArea?.lounge_reservation === 1 ? (
                <ChairsCabanasPicker
                    title={'lounge chairs'}
                    resortName={rootState.currentResort?.name || ''}
                    type={'lounge chairs'}
                    waivesFees={rootState.currentResort?.charge_lounges !== 1}
                    disabled={isFormDisabled}
                    units={loungeQty}
                    setUnits={setLoungeQty}
                    unitPrice={loungePrice}
                    maxUnitsThreshold={maxLoungeQty}
                    defaultUnits={
                        isReservation && rootState.currentReservation?.total_chairs
                            ? rootState.currentReservation.total_chairs
                            : 0
                    }
                />
            ) : null}

            {rootState.currentResortArea?.cabana_reservation === 1 ? (
                <ChairsCabanasPicker
                    title={'cabanas'}
                    resortName={rootState.currentResort?.name || ''}
                    type={'cabanas'}
                    waivesFees={rootState.currentResort?.charge_cabanas !== 1}
                    disabled={isFormDisabled}
                    units={cabanaQty}
                    setUnits={setCabanaQty}
                    unitPrice={cabanaPrice}
                    maxUnitsThreshold={maxCabanaQty}
                    defaultUnits={
                        isReservation && rootState.currentReservation?.total_cabanas
                            ? rootState.currentReservation.total_cabanas
                            : 0
                    }
                />
            ) : null}

            <PrimaryButton
                onClick={completeReservation}
                disabled={!getIsSubmitEnabled()}
                size="large"
                className="rounded-full uppercase"
            >
                {getButtonText()}
            </PrimaryButton>
        </div>
    );
};

export default observer(OrderForm);
