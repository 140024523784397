import * as React from 'react';
import { IonContent } from '@ionic/react';
import { observer } from 'mobx-react';

import { rootState } from 'models/RootState';
import BackArrow from 'components/backArrow/BackArrow';
import ResortAreaView from 'components/map/ResortAreaView';
import PoolSelector from 'components/poolSelector/PoolSelector';
import OrderForm from 'components/order/OrderForm';
import OrderActions from 'components/order/orderActions/OrderActions';

// @TODO remove debug component
import Debug from 'components/Debug';

interface Props {
    isResort?: boolean;
    isReservation?: boolean;
}

const Booking: React.FC<Props> = (props) => {
    const { isResort, isReservation } = props;

    let pageTitle = undefined;

    if (isResort) {
        pageTitle = rootState.currentResort?.name;
    }

    if (isReservation) {
        pageTitle = rootState.currentReservation?.resort?.name;
    }

    return (
        <>
            <BackArrow pageTitle={pageTitle} />
            <IonContent className="ion-no-margin ion-no-padding">
                <ResortAreaView />
                {isResort ? <PoolSelector /> : null}
                <OrderForm {...props} />
                <OrderActions {...props} />
            </IonContent>
        </>
    );
};

export default observer(Booking);
