import React, { PropsWithChildren } from 'react';
import { IonButtons, IonButton, IonHeader, IonToolbar, IonBackButton, IonTitle, IonIcon } from '@ionic/react';
import { arrowBack, create } from 'ionicons/icons';
import { useLocation } from 'react-router-dom';
import { isNil } from 'lodash';
import './BackArrow.css';

const BackArrow: React.FC<PropsWithChildren<any>> = ({ isSignup, isReservation, messagesReturn, ...props }) => {
    const location = useLocation();

    let defaultHref = '/search';

    if (isSignup === 'signup') {
        defaultHref = '/signup';
    } else if (isReservation) {
        defaultHref = '/reservations';
    } else if (!isNil(messagesReturn)) {
        defaultHref = messagesReturn;
    }

    return (
        <IonHeader>
            <IonToolbar>
                <IonButtons slot="start">
                    <IonBackButton text="" icon={arrowBack as any} defaultHref={defaultHref} />
                </IonButtons>
                <IonTitle>{props.pageTitle}</IonTitle>

                {location.pathname === '/profile' && props.mode === 'view' && (
                    <IonButton
                        style={{ height: '20px' }}
                        color="green"
                        type="submit"
                        slot="end"
                        fill="clear"
                        onClick={() => props.editView('edit')}
                    >
                        <IonIcon icon={create} mode="ios" color="green" />
                    </IonButton>
                )}
                {location.pathname === '/profile' && props.mode === 'edit' && (
                    <IonButton slot="end" fill="clear">
                        <button className="btn-position" form="profile-form">
                            save
                        </button>
                    </IonButton>
                )}
            </IonToolbar>
        </IonHeader>
    );
};

export default BackArrow;
