import * as React from 'react';
import { useState } from 'react';
import { IonButton, IonButtons, IonCheckbox, IonItem, IonLabel, IonModal, IonTitle, IonToolbar } from '@ionic/react';

import TermsAndConditions from 'components/TermsAndConditions';
import './OrderConfirmationModal.css';

interface Props {
    showModal: boolean;
    setShowModal: React.Dispatch<React.SetStateAction<boolean>>;
    onOrderConfirmed: any;
}

const OrderConfirmationModal: React.FC<Props> = (props) => {
    const [termsAccepted, setTermsAccepted] = useState<boolean>(false);
    const [colorBtn, setColorBtn] = useState<string>('disabled');

    const tosChecked = () => {
        if (termsAccepted === true) {
            setColorBtn('gold');
        } else {
            setColorBtn('disabled');
        }
    };

    const handleOrderClick = () => {
        props.onOrderConfirmed();
    };

    const getIsOrderDisabled = () => {
        return termsAccepted === false;
    };

    return (
        <IonModal isOpen={props.showModal} cssClass="toc-container mt-safe" showBackdrop={false}>
            <IonToolbar>
                <IonTitle className="confirm-title">Terms and Conditions</IonTitle>
                <IonButtons slot="end">
                    <IonButton onClick={() => props.setShowModal(false)}>Close</IonButton>
                </IonButtons>
            </IonToolbar>

            <div className="toc-text ion-padding-horizontal text-white">
                <TermsAndConditions />
            </div>

            <div className="ion-padding-horizontal ion-padding-vertical mb-safe">
                <IonItem className="toc" onClick={tosChecked} lines="none">
                    <IonCheckbox
                        className="toc-checkbox"
                        mode="md"
                        checked={termsAccepted}
                        onIonChange={(e) => setTermsAccepted(e.detail.checked)}
                    />
                    <IonLabel>Agree to Terms and Conditions</IonLabel>
                </IonItem>
                <br />
                <IonButton
                    color={colorBtn}
                    size="large"
                    className="accept-toc-btn"
                    expand="block"
                    disabled={getIsOrderDisabled()}
                    onClick={handleOrderClick}
                >
                    Accept
                </IonButton>
            </div>
        </IonModal>
    );
};

export default OrderConfirmationModal;
