import * as React from 'react';

import Layout from 'components/Layout';
import Booking from 'components/booking/Booking';
import { rootState } from 'models/RootState';
import { isNull } from 'lodash';
import { useParams } from 'react-router';
import { observer } from 'mobx-react';

const ResortPage: React.FC = () => {
    const { resortId } = useParams<{ resortId: string }>();
    if (isNull(rootState.currentResort)) {
        rootState.setResort(parseInt(resortId));
    }
    // @TODO we do something similar in src/components/resort/ResortCard.tsx:L29, next time extract it
    if (isNull(rootState.currentResortArea)) {
        const resortAreas = rootState.currentResort?.getResortAreas();
        if (resortAreas) {
            rootState.setResortArea(resortAreas[0]);
        }
    }
    return (
        <Layout id="resort-page">
            <Booking isResort={true} />
        </Layout>
    );
};

export default observer(ResortPage);
