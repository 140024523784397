import * as React from 'react';
import { PropsWithChildren } from 'react';
import { IonButton } from '@ionic/react';
import './PrimaryButton.css';

/**
 * PrimaryButton
 *
 * Default Green:
 * `<PrimaryButton />`
 *
 * Large Gold:
 * `<PrimaryButton color="gold" size="large"/>`
 * @param param0
 */

const PrimaryButton: React.FC<PropsWithChildren<any>> = ({ ...props }) => {
    return (
        <IonButton color="green" className="button-primary ion-text-uppercase" fill="solid" expand="block" {...props}>
            {props.children}
        </IonButton>
    );
};

export default PrimaryButton;
