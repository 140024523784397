import { types, onSnapshot, applySnapshot } from 'mobx-state-tree';
import { PushNotification } from '@capacitor/core';
import moment from 'moment';
import { isNull } from 'lodash';

import { StorageService } from '../services/StorageService';
import { LoginResponse } from './LoginResponse';
import User from './User';
import Resort from './Resort';
import ResortArea from './ResortArea';
import Order from './Order';
import { ResortResponse } from './ResortResponse';
import Message from './Message';
import Conversation from './Conversation';
import { errorReporter } from 'services/ErrorReporter';
import Brand from './Brand';

const RootModel = types
    .model({
        api_token: types.maybeNull(types.string),
        user: types.maybeNull(User),
        userOrders: types.array(Order),
        userMessages: types.array(Message),
        adminConversations: types.array(Conversation),
        // @TODO we will have an issue if/when a user orders on their own resorts because the order will exist twice (userOrders & resortOrders) in this tree!
        resortOrders: types.array(Order),
        resorts: types.array(Resort),
        resortsLoadedOn: types.maybeNull(types.Date),
        stripeToken: types.maybe(types.string),
        isResortAdminView: types.optional(types.boolean, false),
        brand: types.optional(Brand, () => Brand.create()),
    })
    /**
     * Volatile state that isn't persisted: https://mobx-state-tree.js.org/concepts/volatiles
     */
    .volatile<{
        isToastOpen: boolean;
        reservedItems: any[];
        toastMessage: PushNotification;
        currentResort: typeof Resort.Type | null | undefined;
        currentResortArea: typeof ResortArea.Type | null | undefined;
        currentReservation: typeof Order.Type | null | undefined;
    }>(() => ({
        isToastOpen: false,
        reservedItems: [],
        toastMessage: {
            id: 'default',
            data: null,
        },
        currentResort: null,
        currentResortArea: null,
        currentReservation: null,
    }))
    /**
     * By default, nodes can only be modified by one of their actions, or by actions higher up in the tree.
     * https://mobx-state-tree.js.org/concepts/actions
     */
    .actions((self) => ({
        /**
         * setup the user state
         * @param loginResponse
         * @param type 'guest' or 'resort'
         */
        setIsToastOpen(isToastOpen: boolean) {
            self.isToastOpen = isToastOpen;
        },
        setReservedItems(reservedItems: any[]) {
            self.reservedItems = reservedItems;
        },
        setToastMessage(toastMessage: PushNotification) {
            self.toastMessage = toastMessage;
        },
        login(loginResponse: LoginResponse, type = 'guest') {
            self.user = User.create(loginResponse.data.data);
            // we don't always get the api_token back depending if it's a register, login or show
            if (loginResponse.data.data.api_token) {
                self.api_token = loginResponse.data.data.api_token;
            }

            // if the login type is resortAdmin and the use isResortAdmin then mark them as isResortAdminView
            self.isResortAdminView = type === 'resort' && self.user.isResortAdmin();
        },
        setUserOrders(orders: any) {
            if (orders) {
                applySnapshot(self.userOrders, orders);
            }
        },
        setResortOrders(orders: any) {
            applySnapshot(self.resortOrders, orders);
        },
        unsetResorts() {
            applySnapshot(self.resorts, []);
            self.resortsLoadedOn = null;
        },
        setResorts(response: ResortResponse) {
            applySnapshot(self.resorts, response.data.data);
            self.resortsLoadedOn = new Date();
        },
        setResort(resortId: number) {
            self.currentResort = self.resorts.find((r) => r.id === resortId) ?? null;
        },
        setResortArea(resortArea: typeof ResortArea.Type) {
            self.currentResortArea = resortArea;
        },
        setAdminConversations(conversations: typeof Conversation.Type[]) {
            applySnapshot(self.adminConversations, conversations);
        },
        setMessages(messages: typeof Message.Type[]) {
            applySnapshot(self.userMessages, messages);
        },
        setReservationById(reservationId: number) {
            self.currentReservation = self.userOrders.find(({ id }) => id === reservationId) ?? null;
        },
        unsetCurrentData() {
            self.currentResort = null;
            self.currentResortArea = null;
            self.currentReservation = null;
        },
        setStripeToken(id: string) {
            self.stripeToken = id;
        },
        setValidTill(date: string) {
            if (self.user) {
                self.user.verified_till = date;
            }
        },
        setBrand(brand: any) {
            self.brand = brand;
        },
    }))
    /**
     * Any fact that can be derived from your state is called a "view"
     * https://mobx-state-tree.js.org/concepts/views
     */
    .views((self) => ({
        shouldLoadResorts() {
            return (
                // we have no resorts
                self.resorts.length === 0 ||
                // we don't know when they were loaded last
                isNull(self.resortsLoadedOn) ||
                this.isResortCacheExpired()
            );
        },
        isResortCacheExpired() {
            // @TODO extend cache from 5 minutes to 24 hours.
            return moment().subtract(5, 'minute').isAfter(rootState.resortsLoadedOn);
        },
        isResortView() {
            return self.isResortAdminView;
        },
        getAPIToken() {
            return self.api_token || self.user?.api_token;
        },
        searchByResortName(query: string) {
            // Return only available resort if white label, ignore query
            if (self.brand.isCustom()) {
                return self.resorts.filter((r) => r.id == self.brand.resort_id);
            }
            return self.resorts
                .filter((r) => !isNull(r.activated_at))
                .filter((r) => r.name.toLowerCase().includes(query.toLowerCase()));
        },
        getOrdersNewestFirst() {
            return self.userOrders.slice().sort((a, b) => {
                return moment(a.reservation_date, 'YYYY-MM-DD') < moment(b.reservation_date, 'YYYY-MM-DD') ? 1 : -1;
            });
        },
        getAdminConversationsNewestFirst() {
            return self.adminConversations.slice().sort((a, b) => {
                return moment(a.getMostRecentMessageDate(), 'YYYY-MM-DD HH-mm-ss') <
                    moment(b.getMostRecentMessageDate(), 'YYYY-MM-DD HH-mm-ss')
                    ? 1
                    : -1;
            });
        },
    }));
const storageService = new StorageService();

const localStorageKey = 'solayRootState';

const rootModelInstance = RootModel.create();

storageService
    .getObject(localStorageKey)
    .then((json) => {
        if (json) {
            if (RootModel.is(json)) {
                applySnapshot(rootModelInstance, json);
            }
        }
    })
    .catch((error) => {
        errorReporter.error('Unable to load local storage', error);
    });

onSnapshot(rootModelInstance, (snapshot) => {
    storageService.setObject(localStorageKey, snapshot);
});

export const rootState = rootModelInstance;
